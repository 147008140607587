import axios from 'axios'

export default {
	install (Vue, options) {
		Vue.prototype.$axios = axios.create({
			baseURL: '/b',
			timeout: 30000
		})
	}
}
