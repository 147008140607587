import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		checkLogin: false,
		user: null
	},
	mutations: {
		user(state, user) {
			state.user = user
		},
		checkLogin(state) {
			state.checkLogin = true
		}
	},
	actions: {
		checkLogin: async ({ commit }) => {
			let axios = Vue.prototype.$axios

			await axios.get('/user').then(resp => {
				commit('user', resp.data.user)
			}).catch((e, xhr) => {
				commit('user', null)
			}).finally(() => {
				commit('checkLogin')
			})
		}
	}
})
