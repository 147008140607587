<template>
	<div class="container page">
		<MenuCard :bid="bid" class="mini" />
		<table class="ctable" v-if="!beforeInit">
			<colgroup>
				<col style="width: 120px;">
				<col>
			</colgroup>
			<tbody>
				<tr>
					<th>글번호</th>
					<td>{{ aid }}</td>
				</tr>
				<tr>
					<th>제목</th>
					<td>{{ article.title }}</td>
				</tr>
				<tr>
					<th>작성자</th>
					<td>{{ article.author }}</td>
				</tr>
				<tr>
					<th>작성일시</th>
					<td>{{ article.date }}</td>
				</tr>
				<tr>
					<th>첨부파일</th>
					<td>
						<div class="file" v-for="file in files" :key="file.fidx">
							<a :href="'/b/board/file/'+file.fidx" target="_blank">{{ file.name }}</a> <span class="file-size">[{{file.size}} B]</span>
						</div>
					</td>
				</tr>
				<tr><td colspan=2 class="content" v-html="article.content"></td></tr>
			</tbody>
		</table>
		<div class="button-area">
			<button @click="listClicked">목록</button>
			<div class="right" v-if="article.author_idx == user_idx">
				<button @click="modifyClicked">수정</button>
				<button @click="deleteClicked">삭제</button>
			</div>
		</div>
		<hr v-if="false">
		<div class="comments-wrapper" v-if="false">
			<h5>댓글</h5>
			<div class="comments" v-if="comments.length == 0">
				<div class="comment">
					<div class="comment-content" style="text-align: center; margin-bottom: 16px;">등록된 댓글이 없습니다.</div>
				</div>
			</div>
			<BoardComments v-for="comment in comments" :key="comment[0].idx" :comments="comment" @write="writeComment" />
			<div class="comments" v-if="user_idx != null">
				<BoardCommentWriteForm @write="writeComment" :cid="0" />
			</div>
		</div>
	</div>
</template>

<script>
import MenuCard from '../components/MenuCard.vue'
import BoardComments from './components/BoardComments.vue'
import BoardCommentWriteForm from './components/BoardCommentWriteForm.vue'

export default {
	name: 'BoardView',
	components: {MenuCard, BoardComments, BoardCommentWriteForm},
	data () {
		return {
			beforeInit: true,
			article: {
				author_idx: 0, date: '', title: '', author: '', content: '', fid: ''
			},
			comments: [],
			files: []
		}
	},
	computed: {
		bid () { return this.$route.params.bid },
		aid () { return this.$route.params.aid },
		user_idx () { return this.$store.state.user != null ? this.$store.state.user.idx : null },
		is_admin() { return this.$store.state.user != null && this.$store.state.user.admin }
	},
	watch: {
		'$route' (to, from) {
			Object.assign(this.$data, this.$options.data());
			this.loadData(null)
		}
	},
	mounted () {
		if (this.$store.state.user == null) {
			this.$router.push({name: 'login'})
		}else{
			this.loadData()
		}
	},
	methods: {
		loadData() {
			this.$axios.get(`/board/${this.bid}/${this.aid}`).then(resp => {
				if (Object.keys(resp.data).includes('article')) {
					this.rawArticle = resp.data.article
					for (let key in resp.data.article) {
						this.article[key] = resp.data.article[key];
					}
				}
				if (Object.keys(resp.data).includes('comments')) {
					this.comments.splice(0)
					resp.data.comments.forEach(it => this.comments.push(it))
				}
				if (Object.keys(resp.data).includes('files')) {
					this.files.splice(0)
					resp.data.files.forEach(it => this.files.push(it))
				}
			}).catch(e => {
				if (e.response !== undefined && e.response.data !== undefined && e.response.data.code !== undefined) {
					if (e.response.data.code == 404) {
						setTimeout(() => { this.$router.push({name: '404'}) }, 1)
						return
					}
				}
				alert('글을 불러오는 중 오류가 발생하였습니다.')
			}).finally(() => {
				this.beforeInit = false
			})
		},
		listClicked () {
			this.$router.push({name: 'BoardList', params: {bid: this.bid}})
		},
		modifyClicked () {
			this.$router.push({name: 'BoardWrite', params: {bid: this.bid, article: this.rawArticle, files: this.files}})
		},
		deleteClicked () {
			if (confirm('삭제하시겠습니까?')) {
				this.$axios.delete(`/board/${this.bid}/${this.aid}`).then(resp => {
					this.$router.push({name: 'BoardList', params: {bid: this.bid}})
				}).catch(e => {
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						alert(e.response.data.error)
					}else{
						alert('삭제 중 오류가 발생하였습니다.')
					}
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.button-area {
	.right {
		float: right;
	}
	
	button {
		border: none;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: 14px;
		margin: 0 8px;
	}
}

.ctable {
	td.content {
		padding-bottom: 50px;
	}
	
	.file {
		a {
			color: #333;
		}
		.file-size {
			font-size: 0.8em;
			color: #666;
		}
	}
}
</style>

<style lang="scss">

</style>
