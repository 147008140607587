<template>
	<nav>
		<ul class="pagination justify-content-center pagination-sm">
			<li class="page-item" v-if="(current - size) > 1">
				<a class="page-link" @click="move(1)">
					<span>&laquo;</span>
				</a>
			</li>
			<li v-for="p in pages" class="page-item" :class="{active: current == p}" :key="p">
				<a class="page-link" @click="move(p)">{{ p }}</a>
			</li>
			<li class="page-item" v-if="(current + size) < last">
				<a class="page-link" @click="move(last)">
					<span>&raquo;</span>
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'Paginantion',
	props: ['current', 'last'],
	data () {
		return {

		}
	},
	computed: {
		size () { return 3 },
		pages () {
			var p = []
			var start = Math.max(this.current - this.size, 1)
			var end = Math.min(this.current + this.size, this.last)
			for (var i = start; i <= end; i++) {
				p.push(i)
			}
			return p
		}
	},
	methods: {
		move(page) {
			this.$emit('click', page)
		}
	}
}
</script>

<style lang="scss">
.pagination a {
	cursor: pointer;
}
</style>
