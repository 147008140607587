<template>
	<div class="container page">
		<MenuCard :bid="'support'" class="mini" />

		<div class="board-write">
			<table class="ctable" style="margin-bottom: 4px;">
				<colgroup>
					<col style="width: 180px;">
					<col>
				</colgroup>
				<tbody>
					<tr>
						<th>제목</th>
						<td class="full"><input type="text" v-model.lazy="form.title"></td>
					</tr>
					<tr>
						<th>휴대폰번호 혹은 메일주소</th>
						<td class="full"><input type="text" v-model.lazy="form.phone"></td>
					</tr>
				</tbody>
			</table>
			<div class="note" style="margin-bottom: 16px; font-size: 0.9em;">※ 휴대폰번호 혹은 메일주소는 문의 답변에 사용됩니다. 답변을 원하실 경우 정확하게 입력해주세요.</div>
			<table class="ctable">
				<tbody>
					<tr><th>문의 내용</th></tr>
					<tr><td class="content"><textarea v-model.lazy="form.content"></textarea></td></tr>
				</tbody>
			</table>
			<div>
				<button class="btn btn-secondary btn-block" @click="done" :disabled="loading">작성 완료</button>
			</div>
		</div>
	</div>
</template>

<script>
import MenuCard from './components/MenuCard.vue'

export default {
	name: 'Support',
	components: {MenuCard},
	data () {
		return {
			loading: false,
			form: {
				title: '', phone: '', content: ''
			}
		}
	},
	methods: {
		done () {
			var keys = ['title', 'content']
			for (var i = 0; i < keys.length; i++) {
				if (this.form[keys[i]].trim() == '') {
					alert('제목과 내용은 필수 항목입니다.')
					return
				}
			}
			
			if (this.loading) return
			this.loading = true
			this.$axios.post('/support', this.form).then(resp => {
				alert('문의 작성 완료! 확인 후 답변드리겠습니다.')
				setTimeout(() => {
					this.$router.push({name: 'home'})
				}, 1)
			}).catch((e, xhr) => {
				if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
					alert(e.response.data.error)
				}else{
					alert('글 저장 중 오류가 발생하였습니다.')
				}
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.ctable {
	td {
		input[type=text], textarea {
			font-size: 16px;
		}

		&.full {
			padding: 0;
			input {
				width: 100%;
				height: 35px;
				border: none;
				padding-left: 8px;
			}
		}
		&.content {
			padding: 0;

			textarea {
				width: 100%;
				height: 300px;
				border: none;
				resize: none;
				padding: 8px;
			}
			textarea:focus {
				outline: none;
			}
		}
	}
}
</style>
