<template>
	<header>
		<div class="container">
			<nav class="navbar navbar-expand-lg navbar-light">
				<router-link class="navbar-brand" :to="{name: 'home'}">PassQ</router-link>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbar">
					<ul class="ml-auto navbar-nav">
						<li class="nav-item">
							<router-link class="nav-link" :to="{name: 'about'}">기관소개</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{name: 'BoardList', params: {bid: 'notice'}}">공지사항</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{name: 'CounselList', params: {bid: 'open'}}">열린상담실</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{name: 'CounselList', params: {bid: 'emerg'}}">자살위기상담실</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{name: 'CounselList', params: {bid: 'parents'}}">학부모상담실</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" :to="{name: 'CounselList', params: {bid: 'teacher'}}">교원고충상담실</router-link>
						</li>
            <li class="nav-item">
							<router-link class="nav-link" :to="{name: 'CounselList', params: {bid: 'meta'}}">메타버스상담실</router-link>
						</li>
						<li class="nav-item" v-if="!logged">
							<router-link class="nav-link" :to="{name: 'login'}" style="font-weight: bold;">로그인</router-link>
						</li>
						<li class="nav-item dropdown" v-if="logged">
							<a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">상담사메뉴</a>
							<div class="dropdown-menu" aria-labelledby="navbarDropdown">
								<router-link class="dropdown-item" :to="{name: 'user_home'}">상담 목록</router-link>
								<div class="dropdown-divider"></div>
								<router-link class="dropdown-item" :to="{name: 'BoardList', params: {bid: 'data'}}">상담자료 게시판</router-link>
								<div class="dropdown-divider" v-if="is_admin"></div>
								<router-link class="dropdown-item" v-if="is_admin" :to="{name: 'admin_stats'}">통계</router-link>
								<a class="dropdown-item" v-if="is_admin" href="/admin/users">회원 관리</a>
								<div class="dropdown-divider"></div>
								<router-link class="dropdown-item" :to="{name: 'user_noti'}">알림 설정</router-link>
								<a class="dropdown-item" href="/user/info">정보 수정</a>
								<a class="dropdown-item" href="/b/user/logout">로그아웃</a>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	</header>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'PageHeader',
	data () {
		return {

		}
	},
	computed: {
		logged () { return this.$store.state.user != null },
		is_admin() { return this.$store.state.user != null && this.$store.state.user.admin }
	},
	mounted () {
		// vue-router 가 페이지 이동을 시켜줘서 자동으로 메뉴가 안 닫힘
		$('.navbar-brand').on('click', function() {
			$('#navbar').collapse('hide')
		})
		$('#navbar a.nav-link:not(.dropdown-toggle)').on('click', function() {
			$('#navbar').collapse('hide')
		})
		$('#navbar a.dropdown-item').on('click', function() {
			$('#navbar').collapse('hide')
		})
	}
}
</script>

<style lang="scss" scoped>
header {
	border-bottom: 1px solid #eaeaea;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
}
.navbar-brand {
	font-weight: bold;
}
#navbar .router-link-active {
	color: rgba(0, 0, 0, 0.9);
}
</style>
