<template>
	<div class="container page">
		<MenuCard :bid="bid" class="mini" />

		<table class="ctable">
			<colgroup>
				<col style="width: 120px;">
				<col>
			</colgroup>
			<tbody>
				<tr>
					<th>글번호</th>
					<td>{{ aid }}</td>
				</tr>
				<tr v-if="!pprotected">
					<th>구분</th>
					<td>{{ article.gubun }}</td>
				</tr>
				<tr v-if="!pprotected">
					<th>지역</th>
					<td>{{ article.region }}</td>
				</tr>
				<tr v-if="!pprotected && article.fields.length > 0">
					<th>영역</th>
					<td>{{ article.fields.join(', ') }}</td>
				</tr>
				<tr v-if="!pprotected && article.facemeet">
					<th>휴대폰번호</th>
					<td>
						{{ article.phone }}
						<div style="color: #106db7;">내담자가 대면/온라인미팅 상담을 요청하였습니다.</div>
					</td>
				</tr>
			</tbody>
		</table>
		<table class="ctable">
			<colgroup>
				<col style="width: 120px;">
				<col>
			</colgroup>
			<tbody>
				<tr>
					<th>제목</th>
					<td>{{ article.title }}</td>
				</tr>
				<tr>
					<th>작성자 닉네임</th>
					<td>{{ article.name }}</td>
				</tr>
				<tr>
					<th>작성일시</th>
					<td>{{ article.date }}</td>
				</tr>
				<tr v-if="!pprotected"><th colspan=2>상담 내용</th></tr>
				<tr v-if="!pprotected"><td colspan=2 class="content" v-html="article.content_escaped"></td></tr>
			</tbody>
		</table>
		<div class="button-area">
			<button @click="listClicked">목록</button>
			<div class="right" v-if="!pprotected && password != ''">
				<button @click="modifyClicked">수정</button>
			</div>
		</div>
		<div class="password-box" v-if="!beforeInit && pprotected">
			<h5 v-if="logged" style="margin-bottom: 16px;">담당 상담 분야가 아닙니다.</h5>
			<h5>글 작성시 입력한 비밀번호를 입력해주세요.</h5>
			<input class="form-control" type="password" v-model="password" @keypress.enter="passwordCheck">
			<p style="color: red;" v-if="wrongPassword">잘못된 비밀번호입니다.</p>
			<button class="btn btn-secondary btn-block" @click="passwordCheck">확인</button>
			<button class="btn btn-default btn-block" @click="back">뒤로가기</button>
		</div>
		<hr>
		<div class="comments-wrapper" v-if="!pprotected">
			<h5>댓글</h5>
			<div class="comments" v-if="comments.length == 0">
				<div class="comment">
					<div class="comment-content" style="text-align: center; margin-bottom: 16px;">아직 등록된 답변이 없습니다.</div>
				</div>
			</div>
			<CounselComments v-for="comment in comments" :key="comment[0].idx" :comments="comment" @write="writeComment" :articleAuthorName="article.name"/>

			<!-- 상담자에게만 표시 -->
			<div class="comments" v-if="logged">
				<h5>답변 작성</h5>
				<p class="answer-warning">※ 꼭 필요한 경우가 아니면 최대 2명의 상담사만 답변을 작성해주세요.</p>
				<CounselCommentWriteForm @write="writeComment" :cid="0" />
			</div>
		</div>

	</div>
</template>

<script>
import MenuCard from '../components/MenuCard.vue'
import CounselComments from './components/CounselComments.vue'
import CounselCommentWriteForm from './components/CounselCommentWriteForm.vue'

export default {
	name: 'CounselView',
	components: {MenuCard, CounselComments, CounselCommentWriteForm},
	data () {
		return {
			beforeInit: true,
			pprotected: true,
			password: '',
			wrongPassword: false,
			article: {
				gubun: '', region: '', fields: [], date: '',
				title: '', name: '', content: ''
			},
			comments: []
		}
	},
	computed: {
		bid () { return this.$route.params.bid },
		aid () { return this.$route.params.aid },
		logged () { return this.$store.state.user != null }
	},
	watch: {
		'$route' (to, from) {
			Object.assign(this.$data, this.$options.data());
			this.loadData(null)
		},
		password () {
			if (this.wrongPassword) this.wrongPassword = false
		}
	},
	mounted () {
		this.loadData(null)
	},
	methods: {
		back () {
			window.history.back()
		},
		passwordCheck () {
			this.loadData(this.password)
		},
		loadData(password) {
			this.$axios.get(`/counsel/${this.bid}/${this.aid}`, {params: {password: password}}).then(resp => {
				this.pprotected = resp.data.protected
				
				// 최초 로딩시에는 password에 ''이 아닌 null이 들어옴.
				// 또한 최초 로딩 + 상담사라면 protected가 풀려서 보임
				// 즉 최초 로딩이 아니면서(password != null) protected 로 응답이 오면 비밀번호가 틀렸다는 소리.
				if (password != null && resp.data.protected) {
					// wrong password
					this.password = ''
					setTimeout(() => this.wrongPassword = true, 10)
					
					return
				}

				if (Object.keys(resp.data).includes('article')) {
					// 상담사가 아니고 비밀번호로 연 경우. 게시글 수정/삭제는 이 경우에만 필요함
					if (!resp.data.protected && password != null) {
						// 수정 페이지로 넘겨주기 위한 게시글 정보 객체 저장
						this.rawArticle = resp.data.article
						this.rawArticle['password'] = password
					}
					for (let key in resp.data.article) {
						this.article[key] = resp.data.article[key];
					}
				}
				if (Object.keys(resp.data).includes('comments')) {
					this.comments.splice(0)
					resp.data.comments.forEach(it => this.comments.push(it))
				}
			}).catch(e => {
				if (e.response !== undefined && e.response.data !== undefined && e.response.data.code !== undefined) {
					if (e.response.data.code == 404) {
						setTimeout(() => { this.$router.push({name: '404'}) }, 1)
						return
					}
				}

				alert('글을 불러오는 중 오류가 발생하였습니다.')
			}).finally(() => {
				this.beforeInit = false
			})
		},
		listClicked () {
			this.$router.push({name: 'CounselList', params: {bid: this.bid}})
		},
		modifyClicked () {
			if (this.rawArticle !== undefined) {
				this.$router.push({name: 'CounselWrite', params: {bid: this.bid, article: this.rawArticle}})
			}
		},
		writeComment(ref, cid, value) {
			ref.loading = true
			var form = {content: value, cid: cid, password: this.password}
			this.$axios.post(`/counsel/${this.bid}/${this.aid}/comment`, form).then(resp => {
				ref.value = ''
				if (cid != 0) {
					ref.$parent.hideReplyForm()
				}
				this.comments.splice(0)
				resp.data.comments.forEach(it => this.comments.push(it))
			}).catch(e => {
				alert('댓글 작성 중 오류가 발생하였습니다.')
			}).finally(() => {
				ref.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.button-area {
	.right {
		float: right;
	}
	
	button {
		border: none;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: 14px;
		margin: 0 8px;
	}
}

.ctable {
	td.content {
		padding-bottom: 50px;
	}
}
</style>

<style lang="scss">
.password-box {
	max-width: 360px;
	margin: 16px auto;
	border: 1px solid #ddd;
	padding: 16px;

	h5 {
		text-align: center;
		margin-bottom: 12px;
		font-size: 16px;
	}
	input {
		margin-bottom: 8px;
	}
}

.answer-warning {
	text-indent: -8px;
	padding: 0 16px 0 24px;
	margin-bottom: 0;
}
</style>
