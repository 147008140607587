<template>
	<div class="container page">
		<MenuCard :bid="$route.params.bid" class="mini" />

		<div class="board-write">
			<h5 v-if="article" style="margin-bottom: 16px;">작성글 수정</h5>
			<table class="ctable">
				<colgroup>
					<col style="width: 120px;">
					<col>
				</colgroup>
				<tbody>
					<tr>
						<th>구분</th>
						<td>
							<label><input type="radio" v-model="form.gubun" value="초등학생" :disabled="article"> 초등학생</label>
							<label><input type="radio" v-model="form.gubun" value="중학생" :disabled="article"> 중학생</label>
							<label><input type="radio" v-model="form.gubun" value="고등학생" :disabled="article"> 고등학생</label>
							<label><input type="radio" v-model="form.gubun" value="학교밖청소년" :disabled="article"> 학교밖청소년</label>
							<label><input type="radio" v-model="form.gubun" value="특수아" :disabled="article"> 특수아</label>
						</td>
					</tr>
					<tr>
						<th>지역</th>
						<td>
							<label><input type="radio" v-model="form.region" value="대구" :disabled="article"> 대구</label>
							<label><input type="radio" v-model="form.region" value="기타 지역" :disabled="article"> 기타 지역</label>
						</td>
					</tr>
					<tr v-if="$route.params.bid == 'open'">
						<th>영역</th>
						<td>
							<label v-for="field in fields" :key="field"><input type="checkbox" v-model="form.fields" :value="field" :disabled="article"> {{ field }}</label>
							<div>※ 1개 이상 선택해주세요.</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table class="ctable" v-if="!article">
				<colgroup>
					<col style="width: 120px;">
					<col>
				</colgroup>
				<tbody>
					<tr>
						<th>휴대폰 번호<br><span>(선택)</span></th>
						<td class="phone">
							<input type="text" v-model="form.phone" maxlength=13><span style="color: #106db7; margin-left: 8px;">예) 010-0000-0000</span>
							<br>
							<label style="margin-top: 8px;"><input type="checkbox" v-model="form.noti"> 답변 알림 문자 받기</label>
							<label v-if="bid == 'emerg' || bid == 'teacher'"><input type="checkbox" v-model="form.facemeet"> 대면 상담 희망 (상담자에게 번호가 공개됩니다)</label>
							<div>
								※ 상담글 찾기 및 답변 알림에 사용됩니다.
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<table class="ctable">
				<colgroup>
					<col style="width: 120px;">
					<col>
				</colgroup>
				<tbody>
					<tr>
						<th>제목</th>
						<td class="full"><input type="text" v-model.lazy="form.title"></td>
					</tr>
					<tr>
						<th>작성자 닉네임</th>
						<td class="full"><input type="text" v-model.lazy="form.name" :disabled="article"></td>
					</tr>
					<tr>
						<th>비밀번호</th>
						<td class="full"><input type="password" v-model.lazy="form.password" :disabled="article"></td>
					</tr>
					<tr v-if="!article">
						<th>비밀번호 확인</th>
						<td class="full"><input type="password" v-model.lazy="password_confirm"></td>
					</tr>
					<tr><th colspan=2>상담 내용</th></tr>
					<tr><td colspan=2 class="content"><textarea v-model.lazy="form.content"></textarea></td></tr>
				</tbody>
			</table>
			<div class="precaution">
				<h4>주의사항</h4>
				<p>1. 여러명의 상담자가 각각 답변을 작성하여, 여러개의 답변이 달릴 수 있습니다.</p>
				<p>2. <u>제목과 작성자 닉네임은 공개</u>됩니다. 작성시 주의해주세요!</p>
				<p><b class="text-danger">3. 비밀번호를 잊어버릴 경우 상담 내용과 답변을 확인할 수 없습니다!</b></p>
			</div>
			<div>
				<button class="btn btn-secondary btn-block" @click="done" :disabled="loading">
					<template v-if="article">수정</template><template v-else>작성 완료</template>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import MenuCard from '../components/MenuCard.vue'

export default {
	name: 'CounselWrite',
	components: {MenuCard},
	props: ['article'],
	data () {
		return {
			loading: false,
			form: {
				gubun: '초등학생', region: '대구', fields: [],
				phone: '', noti: true, facemeet: false,
				title: '', name: '', password: '', content: ''
			},
			password_confirm: ''
		}
	},
	computed: {
		bid () { return this.$route.params.bid },
		fields () { return ['생활교육', '대인관계', '가족', '진로•진학', '학습', '성격', '정신건강', '자해', '성•이성', '기타'] }
	},
	watch: {
		'$route' (to, from) {
			Object.assign(this.$data, this.$options.data());
		}
	},
	mounted () {
		if (this.article !== undefined) {
			this.aid = this.article.aid
			for (let key in this.form) {
				this.form[key] = this.article[key]
			}
		}
	},
	methods: {
		done () {
			// 상담 영역은 열린상담실인 경우에만 있음
			if (this.$route.params.bid == 'open' && this.form.fields.length == 0) {
				alert('상담 영역을 1개 이상 선택해주세요.')
				return
			}
			
			// 값을 다 입력했는지 확인
			var keys = ['title', 'name', 'password', 'content']
			var vals = ['제목', '작성자 닉네임', '비밀번호', '상담 내용']
			for (var i = 0; i < keys.length; i++) {
				if (this.form[keys[i]].trim() == '') {
					alert(`${vals[i]} 항목을 입력해주세요.`)
					return
				}
			}
			
			// 비밀번호 확인, 수정모드에는 비밀번호 입력이 없음
			if (!this.article && this.form.password != this.password_confirm) {
				alert('비밀번호 확인 값이 일치하지 않습니다.')
				return
			}
			
			if (this.loading) return
			this.loading = true
			if (!this.article) {
				// 글 작성
				this.$axios.post(`/counsel/${this.bid}`, this.form).then(resp => {
					alert('글 작성 완료')
					setTimeout(() => {
						this.$router.push({name: 'CounselView', params: {bid: this.bid, aid: resp.data.aid}})
					}, 1)
				}).catch((e, xhr) => {
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						alert(e.response.data.error)
					}else{
						alert('글 저장 중 오류가 발생하였습니다.')
					}
				}).finally(() => {
					this.loading = false
				})
			}else{
				// 글 수정
				this.$axios.patch(`/counsel/${this.bid}/${this.aid}`, this.form).then(resp => {
					alert('수정 완료')
					setTimeout(() => {
						this.$router.go(-1)
					}, 1)
				}).catch((e, xhr) => {
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						alert(e.response.data.error)
					}else{
						alert('글 저장 중 오류가 발생하였습니다.')
					}
				}).finally(() => {
					this.loading = false
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.ctable {
	th {
		span {
			font-size: 12px;
			color: #aaa;
			font-weight: normal;
		}
	}
	td {
		input[type=text], input[type=password], textarea {
			font-size: 16px;
		}
		&.full {
			padding: 0;
			input {
				width: 100%;
				height: 35px;
				border: none;
				padding-left: 8px;
			}
		}
		&.phone {
			input[type=text] {
				width: 180px;
			}

			label {

			}
		}

		&.content {
			padding: 0;

			textarea {
				width: 100%;
				height: 300px;
				border: none;
				resize: none;
				padding: 8px;
			}
			textarea:focus {
				outline: none;
			}
		}

		label {
			margin-bottom: 4px;
			margin-right: 16px;
		}
	}
}

.precaution {
	border: 1px solid #ddd;
	padding: 16px;
	margin-bottom: 16px;
	font-size: 14px;
	line-height: 20px;
	text-indent: -16px;
	padding-left: 32px;

	p {
		margin-bottom: 8px;
	}
}
</style>
