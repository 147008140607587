<template>
	<div class="container page">
		<div class="stats-option">
			<div class="form-inline">
				<b>통계 구분: </b>
				<label><input type="radio" v-model="options.type" value="month"> 월별 통계</label>
				<label><input type="radio" v-model="options.type" value="fields"> 영역별 통계</label>
        <label><input type="radio" v-model="options.type" value="rate"> 평가 통계</label>
			</div>
		</div>
		<div class="stats-option" v-if="options.type == 'month'">
			<div class="form-inline">
				<b>개수 계산 기준: </b>
				<label><input type="radio" v-model="options.count_rule" value="article"> 상담글</label>
				<label><input type="radio" v-model="options.count_rule" value="comment"> 답변</label>
			</div>
			<p>
				* 상담글 기준: 하나의 글에 여러번 답변을 달아도 1개로 카운트됩니다.<br>
				* 답변 기준: 답변 개수를 모두 더합니다.
			</p>
      <div class="form-inline" v-if="options.count_rule == 'article'">
        <b>N번째 답변만 표시: </b>
        <label><input type="radio" v-model="options.only_n_order" value=""> 전체</label>
				<label><input type="radio" v-model="options.only_n_order" value="1"> 1번째</label>
        <label><input type="radio" v-model="options.only_n_order" value="2"> 2번째</label>
				<label><input type="radio" v-model="options.only_n_order" value="3"> 3번째</label>
      </div>
		</div>
    <div class="stats-option" v-if="options.type == 'rate'">
			<p>
				* 기간 필터는 상담글 작성이 아닌 (첫번째) 답변 작성일 기준입니다.<br>
				ex) 3월에 작성된 상담, <b>4월에 상담자가 답변</b>, 5월에 작성자가 평가, 6월에 상담자가 추가 답변시 <b>4월로 집계</b>됩니다.
			</p>
		</div>
    <hr>
		<div class="stats-option">
			<div class="form-inline">
				<b>기간: </b>
				<select class="form-control" v-model="options.start_ym">
					<option v-for="month in months" :value="month.value" :key="month.value">{{ month.label }}</option>
				</select>
				~
				<select class="form-control" v-model="options.end_ym">
					<option v-for="month in months" :value="month.value" :key="month.value">{{ month.label }}</option>
				</select>
			</div>
		</div>
		<button class="btn btn-block btn-success" @click="loadData">조회하기</button>
		<button class="btn btn-block btn-info" v-if="options.type != 'fields'" @click="downloadData">엑셀 저장</button>
		<hr>
		<table v-if="options.type == 'month' && ajax.users.length > 0" class="table table-bordered">
			<thead>
				<tr>
					<th>이름</th>
					<th>전체</th>
					<th>기간합</th>
					<th v-for="month in ajax.months" :key="month">{{ month }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="user in ajax.users" :key="user.idx">
					<th><router-link :to="{name: 'admin_user_page', params: {user_idx: user.idx}}" target="_blank">{{ user.name }}</router-link></th>
					<th>{{ user.cnt }}</th>
					<th>{{ user.cnt_period }}</th>
					<td v-for="month in ajax.months" :key="month">{{ user[month] ? user[month] : 0 }}</td>
				</tr>
			</tbody>
		</table>
		<div v-if="options.type == 'fields'">
			<h4>상담대상별</h4>
			<p class="note">등록된 상담 게시글 기준입니다.</p>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th colspan=5>열린/자살위기</th>
						<th colspan=5>학부모</th>
						<th rowspan=2>교사</th>
            <th colspan=5>메타버스</th>
						<th rowspan=2>소계</th>
					</tr>
					<tr>
						<th v-for="g in gubuns" :key="'open_emerg_' + g">{{ g }}</th>
						<th v-for="g in gubuns" :key="'parents_' + g">{{ g }}</th>
            <th v-for="g in gubuns" :key="'open_emerg_' + g">{{ g }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td v-for="(g, index) in gubuns" :key="'open_emerg_' + g">{{ ajax.subjects.open_emerg ? ajax.subjects.open_emerg[index] : '' }}</td>
						<td v-for="(g, index) in gubuns" :key="'parents_' + g">{{ ajax.subjects.parents ? ajax.subjects.parents[index] : '' }}</td>
						<td>{{ ajax.subjects.teacher }}</td>
            <td v-for="(g, index) in gubuns" :key="'meta_' + g">{{ ajax.subjects.meta ? ajax.subjects.meta[index] : '' }}</td>
						<th>{{ ajax.subjects.total }}</th>
					</tr>
				</tbody>
			</table>
			<h4>상담분야별</h4>
			<p class="note">등록된 상담 게시글 기준으로, 하나의 게시글이 여러개의 분야에 포함될 수 있습니다.</p>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th colspan=10>열린상담실</th>
						<th rowspan=2>위기</th>
						<th rowspan=2>학부모</th>
						<th rowspan=2>교원</th>
						<th rowspan=2>교원(대면)</th>
            <th rowspan=2>메타버스</th>
						<th rowspan=2>소계</th>
					</tr>
					<tr>
						<th v-for="f in 10" :key="f">{{ fields[f-1] }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td v-for="f in fields" :key="f">{{ ajax.fields[f] }}</td>
						<th>{{ ajax.fields.total }}</th>
					</tr>
				</tbody>
			</table>
		</div>
    <div v-if="options.type == 'rate'">
      <table v-if="ajax.rates.length > 0" class="table table-bordered">
        <thead>
          <tr>
            <th>이름</th>
            <th>총 답변 수</th>
            <th>받은 평가 수</th>
            <th>평가 평균</th>
            <th>미평가</th>
            <th>1점</th>
            <th>2점</th>
            <th>3점</th>
            <th>4점</th>
            <th>5점</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in ajax.rates" :key="user.idx">
            <th><router-link :to="{name: 'admin_user_page', params: {user_idx: user.idx}}" target="_blank">{{ user.name }}</router-link></th>
            <th>{{ user.cnt }}</th>
            <th>{{ user.rated_cnt }}</th>
            <th>{{ user.average }}</th>
            <td v-for="(cnt, index) in user.rates" :key="index">{{ cnt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
	</div>
</template>

<script>
import $ from 'jquery'
import XLSX from 'xlsx'

export default {
	name: 'AdminStats',
	data () {
		return {
			options: {
				type: 'month', 
				count_rule: 'article',
        only_n_order: '',
				start_ym: '2020-12',
				end_ym: '2020-12'
			},
			ajax: {users: [], months: [], subjects: {}, fields: {}, rates: []},
			loading: false
		}
	},
	computed: {
		months () {
			var dates = []
			var cur = new Date(2020, 11)
			var today = new Date()
			
			while (cur < today) {
				dates.push({
					value: cur.getFullYear() + '-' + ('0' + (cur.getMonth()+1)).slice(-2), 
					label: cur.getFullYear() + '년 ' + (cur.getMonth()+1) + '월'
				})
				cur.setMonth(cur.getMonth()+1)
			}
			return dates
		},
		gubuns: () => ['초등학생', '중학생', '고등학생', '학교밖청소년', '특수아'],
		fields: () => ['생활교육', '대인관계', '가족', '진로•진학', '학습', '성격', '정신건강', '자해', '성•이성', '기타', '위기', '학부모', '교원', '교원(대면)', '메타']
	},
	mounted () {
		var today = new Date()
		this.options.end_ym = today.getFullYear() + '-' + ('0' + (today.getMonth()+1)).slice(-2)
	},
	methods: {
		loadData () {
			if (this.loading) return
			
			this.loading = true
			this.ajax.users.splice(0)
			this.ajax.months.splice(0)
			
			this.$axios.get('/admin/stats', {params: this.options}).then(resp => {
				if (resp.data.users) this.$set(this.ajax, 'users', resp.data.users)
				if (resp.data.months) this.$set(this.ajax, 'months', resp.data.months)
				if (resp.data.subjects) this.$set(this.ajax, 'subjects', resp.data.subjects)
				if (resp.data.fields) this.$set(this.ajax, 'fields', resp.data.fields)
        if (resp.data.rates) this.$set(this.ajax, 'rates', resp.data.rates)
				
			}).catch(e => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '불러오는 중 오류가 발생하였습니다.'
				alert(message)
			}).finally(() => {
				this.loading = false
			})
		},
		downloadData() {
      if (this.options.type == 'month') {
        this.downloadMonths()
      } else if (this.options.type == 'rate') {
        this.downloadRates()
      }
		},
    downloadMonths() {
      if (this.ajax.months.length == 0) {
          alert('조회하기 후 저장 버튼을 눌러주세요.')
          return
        }
        
        var wb = XLSX.utils.book_new()
        
        // 
        var ajax = this.ajax
        var data = [
          ['이름', '전체합', '기간합'].concat(ajax.months) // header
        ];
        
        for (let user of ajax.users) {
          var row = [user.name, user.cnt, user.cnt_period]
          
          for (let month of ajax.months) {
            row.push(user[month] ?? 0)
          }
          data.push(row)
        }
        
        // Make sheet
        var ws = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet')
        
        // Donwload
        XLSX.writeFile(wb, 'passq.xlsx', {})
    },
    downloadRates() {
      if (this.ajax.rates.length == 0) {
          alert('조회하기 후 저장 버튼을 눌러주세요.')
          return
        }

        var wb = XLSX.utils.book_new()
        var data = [
          ['이름', '총 답변 수', '받은 평가 수', '평가 평균', '미평가', '1점', '2점', '3점', '4점', '5점']
        ]

        for (let user of this.ajax.rates) {
          var row = [user.name, user.cnt, user.rated_cnt, user.average].concat(user.rates)
          data.push(row)
        }

        // Make sheet
        var ws = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet')
        
        // Donwload
        XLSX.writeFile(wb, 'passq_rates.xlsx', {})
    }
	}
}
</script>

<style lang="scss" scoped>
.stats-option {
	margin-bottom: 16px;
	
	& > p {
		margin-left: 16px;
	}
}
.form-inline > * {
	margin-right: 8px;
	margin-left: 8px;
}

th {
	text-align: center;
	background-color: #efefef;
	vertical-align: middle !important;
}
</style>
