import NProgress from 'nprogress'

/*
import NProgress from 'nprogress'
router.beforeEach((to, from, next) => {
	NProgress.start()
	next()
})
router.afterEach(() => {
	NProgress.done(true)
})

//import '~nprogress/nprogress.css'
*/

export default {
	install (Vue, options) {
		// https://github.com/vue-bulma/nprogress
		Vue.mixin({
			beforeCreate () {
				let requestsTotal = 0
				let requestsCompleted = 0

				function setComplete() {
					requestsTotal = 0
					requestsCompleted = 0
					NProgress.done()
				}
				function initProgress() {
					if (0 === requestsTotal) {
						setTimeout(() => NProgress.start(), 100)
					}
					requestsTotal++
					NProgress.set(requestsCompleted / requestsTotal)
				}
				function increase() {
					setTimeout(() => {
						requestsCompleted++
						if (requestsCompleted >= requestsTotal) {
							setComplete()
						}else{
							NProgress.set((requestsCompleted / requestsTotal) - 0.1)
						}
					}, 150)
				}
				
				// eslint-disable-next-line
				if (true) {
					let axios = Vue.prototype.$axios
					axios.interceptors.request.use((request) => {
						initProgress()
						return request
					}, (error) => {
						return Promise.reject(error)
					})

					axios.interceptors.response.use((response) => {
						increase()
						return response
					}, (error) => {
						increase()
						return Promise.reject(error)
					})
				}
			}
		})

		Vue.prototype.$nprogress = NProgress
	}
}
