<template>
	<div class="comment write">
		<div class="comment-author">
			{{ print_name }}
			<button class="comment-write-button" @click="buttonClicked" :disabled="loading">게시</button>
		</div>
		<textarea v-model="value"></textarea>
		<button v-if="false" @click="buttonClicked" :disabled="loading">댓글 달기</button>
	</div>
</template>
<script>
export default {
	name: 'CounselCommentWriteForm',
	props: ['cid', 'articleAuthorName'],
	data () {
		return {
			value: '',
			loading: false
		}
	},
	computed: {
		print_name () {
			//return this.$root.user !== null ? (this.$root.user.name + ' (' + this.$root.user.affiliation + ')') : this.articleAuthorName
			return this.$store.state.user !== null ? this.$store.state.user.name : this.articleAuthorName
		}
	},
	methods: {
		buttonClicked () {
			if (this.value.trim() != '') {
				this.$emit('write', this, this.cid, this.value.trim())
			}
		}
	}
}
</script>