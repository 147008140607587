<template>
	<div class="container page">
		<div class="error-image">
			<img src="/static/images/error.png">
		</div>
		<h4>존재하지 않는 페이지입니다.</h4>
	</div>
</template>

<script>
export default {
	name: 'NotFound',
	data () {
		return {

		}
	}
}
</script>

<style lang="scss" scoped>
.error-image {
	text-align: center;

	img {
		max-width: 240px;
	}
}

h4 {
	text-align: center;
}
</style>
