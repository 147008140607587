<template>
	<div class="menu-card" v-if="info">
		<div class="menu-card-image">
			<img :src="info.image">
		</div>
		<div class="menu-card-content">
			<h4>{{ info.name }}</h4>
			<p v-html="info.text"></p>
		</div>
	</div>
</template>
<script>

export default {
	name: 'MenuCard',
	props: ['bid'],
	computed: {
		info () {
			return {
				open: {
					name: '열린상담실',
					image: '/static/images/open.png',
					text: '누구나 회원가입 없이 이용할 수 있는 무료<br>\
						<u>열린상담실</u>입니다.<br>\
						패스큐 전문 카운슬러의<br>\
						전문적인 상담을 받을 수 있습니다.'
				},
				emerg: {
					name: '자살위기상담실',
					image: '/static/images/emerg.png',
					text: '당신은 그 존재만으로도<br>\
						아름답고 가치있는 사람입니다.<br>\
						포기하지 마시고 <u>위기상담실</u>을 찾아주세요.<br>\
						패스큐가 가까이에 있습니다.'
				},
				parents: {
					name: '학부모상담실',
					image: '/static/images/parents2.png',
					text: '당신의 자녀는 우리 모두의 자녀입니다.<br>\
						패스큐와 함께<br>\
						자녀에 대한 고민을 나눌 수 있습니다.'
				},
				teacher: {
					name: '교원고충상담실',
					image: '/static/images/teacher.png',
					text: '일선 선생님들의 행복을 위해<br>\
						고충을 함께 나누고 해결해나가는<br>\
						교원고충 비밀상담실입니다.'
				},
        meta: {
          name: '메타버스상담실',
          image: '/static/images/meta.png',
          text: '메타버스 상담실에서 상담을 받을 수 있습니다.<br>\
상담 가능한 시간을 신청해주시면 상담실 링크를 답변으로 남겨드립니다.<br>\
(이용가능 시간: 18:00~24:00)<br><br>\
<a href="https://go.zepeto.me/lReDOU" target="_blank">상담실 예시</a>'
        },
				notice: {
					name: '공지사항',
					image: '/static/images/book.png',
					text: '패스큐에 관한 공지사항을 확인할 수 있습니다.'
				},
				data: {
					name: '상담자료 게시판',
					image: '/static/images/book.png',
					text: '상담자료 게시판입니다.'
				},
				user_home: {
					name: '상담 목록',
					image: '/static/images/emerg2.png',
					text: '담당 상담분야에 해당하는 게시글과 답변을 달았던 글만 모아둔 공간입니다.'
				},
				admin_users: {
					name: '회원 관리',
					image: '/static/images/users.png',
					text: ''
				},
				support: {
					name: '사이트이용문의',
					image: '/static/images/error.png',
					text: '사이트 이용 중에 발생하는 오류 / 개선 요청 등을 문의할 수 있습니다.'
				}
			}[this.bid]
		}
	}
}
</script>
<style lang="scss">
.menu-card {
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
	min-height: 180px;
	height: 220px;
	padding: 10px 20px;
	position: relative;
	margin: 0 auto 24px;
	color: #000;

	&-image {
		float: right;
		img {
			width: 160px;

			@media (min-width: 768px) {

			}
			@media (min-width: 992px) {
				width: 120px;
			}
			@media (min-width: 1200px) {
				width: 160px;
			}
		}
	}
	&-content {
		margin-top: 20px;
		width: calc(100% - 180px);
		min-width: 320px;

		h4 {
			font-weight: bold;
			margin-bottom: 16px;
		}
		p {
			color: #bbb;
			word-break: keep-all;
		}
		u {
			font-weight: bold;
		}
	}

	&.mini {
		min-height: auto;

		.menu-card-image {
			float: left;
			img {
				width: auto;
				height: 60px;
			}
		}

		.menu-card-content {
			width: 100%;

			h4 {
				margin-left: 70px;
			}
		}
	}

  &.md-nano {
    a {
      display: none;
    }
  }
	
	@media (max-width: 991px) {
		&.md-nano {
			min-height: auto;
			height: auto;
		
			.menu-card-image {
				float: left;
				img {
					width: auto;
					height: 60px;
				}
			}
		
			.menu-card-content {
				margin-left: 80px;
				margin-top: 16px;
			
				p {
					display: none;
				}
			}
		}
	}

	@media (max-width: 480px) {
		& {
			height: auto;
		}
		&-image {
			float: none;
			text-align: center;
		}
		&-content {
			width: 100%;
		}
	}
}
</style>
