<template>
	<div class="container page">
		<UserInfoForm :mode="'join'" :user="user" :errorMessage="errorMessage" @click="join" />
	</div>
</template>

<script>
import UserInfoForm from './components/UserInfoForm.vue'

export default {
	name: 'Join',
	components: {UserInfoForm},
	data () {
		return {
			loading: false,
			errorMessage: '',
			user: {
				id: '', pw: '', name: '', phone: '', affiliation: '', position: '',
				experience: [], license: [], fields: [], gubuns: []
			}
		}
	},
	watch: {
		user: {
			deep: true,
			handler() {
				this.errorMessage = ''
			}
		}
	},
	methods: {
		join () {
			if (this.loading) return
			this.loading = true

			this.$axios.post('/user', this.user).then(resp => {
				alert('가입 요청이 완료되었습니다. 관리자 승인 후 로그인 가능합니다.')
				setTimeout(() => this.$router.push({name: 'home'}), 1)
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '회원가입 중 오류가 발생하였습니다.'
				setTimeout(() => this.errorMessage = message, 10)
				//alert(message)
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss">

</style>
