<template>
	<div class="container page">
		<div class="password-box" v-if="pprotected">
			<h5>회원정보 수정을 위해<br>사용자 비밀번호를 다시 입력해주세요.</h5>
			<input class="form-control" type="password" v-model="password" @keypress.enter="passwordCheck">
			<p style="color: red;" v-if="errorMessage != ''">{{ errorMessage }}</p>
			<button class="btn btn-secondary btn-block" @click="passwordCheck">확인</button>
			<button class="btn btn-default btn-block" @click="back">뒤로가기</button>
		</div>
		<UserInfoForm v-if="!pprotected" :mode="'modify'" :user="user" :errorMessage="errorMessage" @click="modify" />
	</div>
</template>

<script>
import UserInfoForm from '../components/UserInfoForm.vue'

export default {
	name: 'UserInfo',
	components: {UserInfoForm},
	data () {
		return {
			password: '',
			errorMessage: '',
			loading: false,
			pprotected: true,
			user: {
				id: '', old_pw: '', new_pw: '', name: '', phone: '', affiliation: '', position: '',
				experience: [], license: [], fields: [], gubuns: []
			}
		}
	},
	watch: {
		password () {
			this.errorMessage = ''
		}
	},
	created () {
		if (this.$store.state.user == null) this.$router.push({name: 'home'})
	},
	methods: {
		passwordCheck () {
			if (this.loading) return
			this.loading = true

			var user_id = this.$store.state.user['id']
			this.$axios.post('/user/login', {id: user_id, password: this.password, mode: 'login'}).then(resp => {
				this.pprotected = false
				this.$store.commit('user', resp.data.user)
				for (let key in this.user) {
					this.user[key] = this.$store.state.user[key]
				}
			}).catch((e, xhr) => {
				this.password = ''

				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '오류가 발생하였습니다.'
				setTimeout(() => this.errorMessage = message, 10)
			}).finally(() => {
				this.loading = false
			})
		},
		back () {
			window.history.back()
		},
		modify () {
			if (this.loading) return
			this.loading = true

			this.user.old_pw = this.password
			this.$axios.patch('/user', this.user).then(resp => {
				alert('수정 완료하였습니다.')
				location.reload()
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '정보 수정 중 오류가 발생하였습니다.'
				setTimeout(() => this.errorMessage = message, 10)
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.password-box {
	max-width: 360px;
	margin: 16px auto;
	border: 1px solid #ddd;
	padding: 16px;

	h5 {
		font-size: 18px;
		text-align: center;
		margin-bottom: 12px;
	}
	input {
		margin-bottom: 8px;
	}
}
</style>
