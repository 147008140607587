<template>
	<div class="container page">
		<MenuCard :bid="bid" class="mini" />
		<div v-if="bid == 'emerg'" class="row" style="margin: 16px 0 16px;">
			<div class="col-md-3 col-6">
				<a href="tel:1388">
					<div class="call-card">
						<h5>청소년 전화</h5>
						<span>1388</span>
					</div>
				</a>
			</div>
			<div class="col-md-3 col-6">
				<a href="tel:129">
					<div class="call-card">
						<h5>희망의 전화</h5>
						<span>129</span>
					</div>
				</a>
			</div>
			<div class="col-md-3 col-6">
				<a href="tel:1588-9191">
					<div class="call-card">
						<h5>생명의 전화</h5>
						<span>1588-9191</span>
					</div>
				</a>
			</div>
			<div class="col-md-3 col-6">
				<a href="tel:1577-0199">
					<div class="call-card">
						<h5>자살예방 핫라인</h5>
						<span>1577-0199</span>
					</div>
				</a>
			</div>
		</div>
		<div style="margin-bottom: 16px;">
			<router-link :to="{name: 'CounselWrite', bid: bid}" role="button" class="btn btn-primary btn-block">글쓰기</router-link>
		</div>
		<div class="search-wrapper">
			<fieldset>
				<legend>내 상담글 찾기</legend>
				<div class="row">
					<div class="col-md-5 col-6">
						<label>휴대폰번호 <input type="text" v-model="searchParams.phone" autocomplete="new-password"></label>
					</div>
					<div class="col-md-5 col-6">
						<label>비밀번호 <input type="password" v-model="searchParams.password" @keypress.enter="search" autocomplete="new-password"></label>
					</div>
					<div class="col-md-2 col-12">
						<button class="btn btn-secondary btn-block" @click="search">찾기</button>
						<button class="btn btn-block" @click="searchReset">초기화</button>
					</div>
				</div>
			</fieldset>
		</div>
		<CounselListTable ref="table" :searchParams="searchParams"></CounselListTable>
	</div>
</template>

<script>
import MenuCard from '../components/MenuCard.vue'
import CounselListTable from './components/CounselListTable.vue'
import $ from 'jquery'

export default {
	name: 'CounselList',
	components: {MenuCard, CounselListTable},
	data () {
		return {
			loading: true,
			searchParams: {phone: '', password: ''}
		}
	},
	watch: {
		'$route' (to, from) {
			this.loadList(1)
		}
	},
	computed: {
		bid () { return this.$route.params.bid }
	},
	methods: {
		loadList (page) {
			this.$refs.table.loadList(page)
		},
		search() {
			this.searchParams.phone = this.searchParams.phone.trim()
			this.searchParams.password = this.searchParams.password.trim()

			if (this.searchParams.phone == '' || this.searchParams.password == '') {
				alert('휴대폰 번호와 글 작성 당시 비밀번호를 모두 입력해주세요.')
				return
			}
			this.loadList(1)
		},
		searchReset() {
			this.searchParams.phone = ''
			this.searchParams.password = ''
			this.loadList(1)
		}
	}
}
</script>

<style lang="scss">
.search-wrapper {
	fieldset {
		padding: 4px 16px 16px;
		border: 1px solid #033f63;

		legend {
			color: #033f63;
			font-size: 16px;
			width: auto;
		}
	}
	label {
		display: block;
	}
	input {
		height: 26px;
		width: 100%;
		border: 1px solid #555;
		margin-right: 5px;
		vertical-align: middle;
	}

	button {
		padding: 3px 8px;
		border: 1px solid #555;
		font-size: 14px;
		vertical-align: middle;
	}
	margin-bottom: 16px;
}

.call-card { // 위기상담실 게시판 하단 전화번호
	background-color: #fff;
	box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
	border-radius: 8px;
	text-align: center;
	color: #000;
	line-height: 28px;
	margin: 0 auto 16px;
	padding: 8px 16px 4px;

	h5 {
		font-size: 16px;
		font-weight: bold;
		word-break: keep-all;
	}
	span {
		font-size: 14px;

	}
}
</style>
