<template>
	<div class="form-rate">
		<span v-if="readonly">{{ author }} 선생님이 받은 평가</span>
    <span v-else>{{ author }} 선생님의 상담이 도움이 되었나요?</span>
    <div class="rates" v-if="readonly">
			<div v-for="i in 5" :key="i" 
				ref="stars" 
				class="star" :class="{rated: i <= rate}"></div>
		</div>
		<div class="rates" v-else>
			<div v-for="i in 5" :key="i" 
				ref="stars" 
				class="star" :class="{rated: i <= preview}" 
				@click="rateClicked(i)" @pointerover="preview = i" @pointerleave="preview = rate"></div>
		</div>
		<div class="rate-text">{{ rate_text }}</div>
	</div>
</template>
<style lang="scss">
.form-rate {
	padding: 8px;
	margin-bottom: 8px;
	text-align: center;
	
	> span {
		border-top: 1px solid #ddd;
		padding: 8px 16px 0;
	}
	
	.rates {
		display: flex;
		justify-content: center;
		margin-top: 4px;
		
		.star {
			background-image: url('./assets/star.png');
			width: 32px;
			height: 32px;
			background-size: cover;
			background-origin: content-box;
			background-repeat: no-repeat;
			padding: 2px;
			
			&.rated {
				background-image: url('./assets/star-fill.png');
			}
		}
	}
	.rate-text {
		color: #aaa;
		margin-top: 8px;
		font-size: 0.9em;
	}
}
</style>
<script>
export default {
	name: 'CounselRate',
	props: ['author', 'rate', 'readonly'],
	data () {
		return {
			preview: 0,
		}
	},
  watch: {
    rate(newVal) {
      this.preview = newVal
    }
  },
	computed: {
		rate_text () {
			return ['평가 없음', '많이 미흡했어요', '미흡했어요', '보통이에요', '만족했어요', '매우 만족했어요'][this.preview]
		}
	},
	methods: {
		rateClicked(rate) {
			if (rate == this.rate) rate = 0;
      this.$emit('changed', rate)
		}
	},
	beforeMount() {
		this.preview = this.rate
	}
}
</script>