<template>
	<div class="comments">
		<div class="comment" :class="{re: index != 0}" v-for="(cmt, index) in comments" :key="cmt.id">
			<div class="comment-header">
				<div class="author">{{ cmt.author }}</div>
				<div class="header-right">
					<div class="options" v-if="cmt.author_idx == my_idx">
						<a @click="modifyClicked(index)">{{ modifying_index == index ? '취소' : '수정' }}</a> · <a @click="deleteClicked">삭제</a> · 
					</div>
					<div class="date">{{ cmt.date }}</div>
				</div>
			</div>
			<div class="comment-content" v-show="modifying_index != index" v-html="cmt.content_escaped"></div>
			<textarea class="comment-textarea" v-if="modifying_index == index" v-model="new_value"></textarea>
			<div class="comment-footer">
				<a @click="replyClicked" v-show="modifying_index != index" v-if="index == comments.length - 1">{{ showReplyForm ? '취소' : '대댓글' }}</a>
				<button class="comment-write-button" @click="modifySubmitClicked" v-if="modifying_index == index" :disabled="loading">게시</button>
			</div>
		</div>
		<BoardCommentWriteForm class="re" v-show="showReplyForm" v-on="$listeners" :cid="comments[0].id" />
	</div>
</template>
<script>
import BoardCommentWriteForm from './BoardCommentWriteForm.vue'

export default {
	name: 'BoardComments',
	components: {BoardCommentWriteForm},
	props: ['comments'],
	data () {
		return {
			showReplyForm: false,
			modifying_index: null,
			new_value: '',
			loading: false
		}
	},
	computed: {
		my_idx () { return this.$store.state.user != null ? this.$store.state.user.idx : 0 }
	},
	methods: {
		hideReplyForm () {
			this.showReplyForm = false
		},
		replyClicked () {
			this.showReplyForm = !this.showReplyForm
		},
		modifyClicked (index) {
			if (this.modifying_index == index) {
				this.modifying_index = null
				return
			}else{
				this.modifying_index = index
				this.new_value = this.comments[index].content
			}
		},
		modifySubmitClicked () {
			if (this.modifying_index == null || this.loading) return
			
			var comment = this.comments[this.modifying_index]
			
			this.loading = true
			var form = {content: this.new_value, cid: comment.id}
			this.$axios.patch(`/board/${this.$parent.bid}/${this.$parent.aid}/comment`, form).then(resp => {
				comment.content = resp.data.content
				comment.content_escaped = resp.data.content_escaped
				
				this.modifying_index = null
			}).catch(e => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '댓글 수정 중 오류가 발생하였습니다.'
				alert(message)
			}).finally(() => {
				this.loading = false
			})
			
		},
		deleteClicked () {
			if (confirm('댓글을 삭제하시겠습니까?')) {
				/*this.$axios.delete(`/board/${this.bid}/${this.aid}`).then(resp => {
					this.$router.push({name: 'BoardList', params: {bid: this.bid}})
				}).catch(e => {
					if (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined) {
						alert(e.response.data.error)
					}else{
						alert('삭제 중 오류가 발생하였습니다.')
					}
				})*/
			}
		}
	}
}
</script>
