<template>
	<div id="app">
		<div is="PageHeader"></div>
		<router-view></router-view>
		<div is="PageFooter"></div>	</div>
</template>

<script>
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'nprogress/nprogress.css'

import PageHeader from './views/components/PageHeader.vue'
import PageFooter from './views/components/PageFooter.vue'

export default {
	name: 'App',
	components: {PageHeader, PageFooter},
}
</script>

<style lang="scss">
@font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
	font-weight: 700;
    src: url('/static/fonts/NanumGothicBold.eot');
    src: url('/static/fonts/NanumGothicBold.eot') format('embedded-opentype'),
         url('/static/fonts/NanumGothicBold.woff') format('woff');
}
@font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
	font-weight: 400;
    src: url('/static/fonts/NanumGothic.eot');
    src: url('/static/fonts/NanumGothic.eot') format('embedded-opentype'),
         url('/static/fonts/NanumGothic.woff') format('woff');
}
html, body {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 16px;
	margin: 0;
	padding: 0;
	height: 100%;
}
.page {
	padding-top: 24px;
	padding-bottom: 120px;
}
input:focus, button:focus {
	outline: none;
}

#app {
	position: relative;
	min-height: 100%;
}

/* 게시글 보기 / 작성시 테이블 스타일 */
.ctable {
	border: 1px solid #ddd;
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
	border-collapse: collapse;
	border-spacing: 0;
	color: #555;

	th, td {
		border: 1px solid #ddd;
		padding: 8px;
		line-height: 1.42857143;
		margin: 0;
		font-size: 14px;
	}
	th {
		text-align: center;
		background-color: #f6f6f6;
	}
}

p {
	margin-bottom: 0.5rem;
}


/* 댓글 공통 CSS */
.comments-wrapper {
	h5 {
		margin-bottom: 16px;
	}
}
.comments {
	margin-bottom: 16px;
	border-bottom: 1px solid #ddd;
	font-size: 14px;
	
	
	.comment {
		&-header {
			position: relative;
			background-color: #efefef;
			padding: 4px 8px;

			.author {
				display: inline-block;
				margin-right: 8px;
			}
			.header-right {
				float: right;
				margin-top: 1px;
				font-size: 14px;

				.date {
					display: inline-block;
					color: #aaa;
				}
				.options {
					display: inline-block;
					margin-left: 8px;
					a {
						color: #516B82;
						cursor: pointer;
					}
				}
			}
			&:after {
				display: block;
				content: ' ';
				clear: both;
			}
		}
		
		&-content {
			padding: 4px 8px 8px;
		}
		
		&-textarea {
			width: 100%;
			resize: none;
			padding: 8px;
			height: 120px;
			border: 1px solid #ccc;
			border-radius: 0;
			font-size: 16px;
			
			&:focus {
				outline: none;
			}
		}
		
		&-footer {
			text-align: right;
			font-size: 14px;
			padding: 0 8px 8px;
			color: #516B82;

			a {
				cursor: pointer;
			}
		}

		&.re {
			margin-left: 24px;
		}
		
		&.write {
			position: relative;
			padding: 8px;

			.comment-author {
				background-color: #e8f1ff;
				padding: 4px 8px;
				border: 1px solid #ccc;
				border-bottom: none;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				
				.comment-write-button {
					float: right;
				}
			}

			textarea {
				width: 100%;
				resize: none;
				padding: 8px;
				height: 120px;
				border: 1px solid #ccc;
				border-radius: 0;
				font-size: 16px;
			}
			textarea:focus {
				outline: none;
			}
			& > button {
				width: 100%;
				margin-top: -10px;
				border: none;
				border-radius: 4px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				line-height: 36px;
				color: #fff;
				background-color: #458DFC;
			}
		}
	}
}

.comment-write-button {
	background: #458DFC;
	border: none;
	border-radius: 4px;
	font-size: 14px;
	color: #fff;
}
.comment-cancel-button {
	border: none;
	border-radius: 4px;
	font-size: 14px;
	margin-right: 8px;
}
</style>
