<template>
	<div class="container page">
		<div class="error-image">
			<img src="/static/images/error.png">
		</div>
		<h4>
			인터넷 익스플로러에서는 사용할 수 없는 페이지입니다.<br>
			Edge, Chrome, 웨일 등 다른 브라우저를 사용해주세요.
		</h4>
	</div>
</template>

<script>
export default {
	name: 'IEError',
	data () {
		return {

		}
	}
}
</script>

<style lang="scss" scoped>
.error-image {
	text-align: center;

	img {
		max-width: 240px;
	}
}

h4 {
	text-align: center;
}
</style>
