<template>
	<div class="container page">
		<div class="login-wrapper">
			<h4>상담사 로그인</h4>
			<div class="field">
				<label for="uid">ID <span style="font-size: 0.8em;">or 휴대폰번호</span></label>
				<input class="form-control" type="text" id="uid" v-model="id" @input="removeError" :disabled="forgot_password == 2">
			</div>
			<div v-show="forgot_password == 0">
				<div class="field">
					<label for="upw">비밀번호</label>
					<input class="form-control" type="password" id="upw" v-model="password" @input="removeError" @keypress.enter="login(false)">
				</div>
				<div class="field">
					<p style="color: red;" v-if="errorMessage != ''">{{ errorMessage }}</p>
				</div>
				<div class="field forgot-password">
					<a @click="showForgotPwForm">비밀번호를 잊어버리셨나요?</a>
				</div>
				<button class="btn btn-secondary btn-block" @click="login(false)">로그인</button>
				<router-link :to="{name: 'join'}" role="button" class="btn btn-light btn-block">회원가입</router-link>
			</div>
			<div v-show="forgot_password == 1">
				<p>휴대폰번호로 임시 비밀번호를 보내드립니다.</p>
				<div class="field">
					<p style="color: red;" v-if="errorMessage != ''">{{ errorMessage }}</p>
				</div>
				<button class="btn btn-secondary btn-block" @click="requestTempPw">임시 비밀번호 보내기</button>
				<button class="btn btn-light btn-block" @click="backToLogin">로그인으로 돌아가기</button>
			</div>
			<div v-show="forgot_password == 2">
				<div class="field">
					<label for="upw_temp">임시 비밀번호 <span style="font-size: 0.9em; color: red;">{{ parseInt(temp_pw_remains / 60) }}:{{ ('0' + (temp_pw_remains % 60)).slice(-2) }}</span></label>
					<input class="form-control" type="password" id="upw_temp" v-model="password" @input="removeError">
				</div>
				<div class="field">
					<label for="upw_new">새 비밀번호</label>
					<input class="form-control" type="password" id="upw_new" v-model="new_password" @input="removeError" @keypress.enter="login(true)">
				</div>
				<div class="field">
					<p style="color: red;" v-if="errorMessage != ''">{{ errorMessage }}</p>
				</div>
				<button class="btn btn-secondary btn-block" @click="login(true)">임시 비밀번호로 로그인</button>
				<button class="btn btn-light btn-block" @click="backToLogin">로그인으로 돌아가기</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'login',
	data () {
		return {
			loading: false,
			forgot_password: 0,
			temp_pw_remains: 600, temp_timer: null,
			id: '', password: '', new_password: '',
			errorMessage: ''
		}
	},
	computed: {
		fields () { return ['생활교육', '대인관계', '가족', '진로•진학', '학습', '성격', '정신건강', '자해', '성•이성', '기타'] }
	},
	methods: {
		backToLogin () {
			this.errorMessage = ''
			this.password = ''
			this.new_password = ''
			this.forgot_password = 0
		},
		showForgotPwForm() {
			this.errorMessage = ''
			this.password = ''
			this.new_password = ''
			this.forgot_password = 1
		},
		startTimer() {
			if (this.temp_timer != null) {
				clearInterval(this.temp_timer)
			}
			this.temp_pw_remains = 600
			this.temp_timer = setInterval(() => {
				this.temp_pw_remains -= 1
				if (this.temp_pw_remains == 0) {
					clearInterval(this.temp_timer)
					this.temp_timer = null
				}
			}, 1000)
		},
		requestTempPw() {
			if (this.loading) return
			this.errorMessage = ''

			this.id = this.id.trim()
			if (this.id == '') {
				this.errorMessage = 'ID 혹은 휴대폰번호를 입력해주세요.'
				return
			}

			this.$axios.post('/user/login/forgot', {id: this.id}).then(resp => {
				this.forgot_password = 2
				this.startTimer()
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '요청 중 오류가 발생하였습니다.'
				setTimeout(() => this.errorMessage = message, 10)
			}).finally(() => {
				this.loading = false
			})
		},
		login (isTempLogin) {
			if (this.loading) return

			this.id = this.id.trim()
			this.password = this.password.trim()
			this.new_password = this.new_password.trim()

			if (this.id == '') {
				this.errorMessage = 'ID 혹은 휴대폰번호를 입력해주세요.'
				return
			}
			if (this.password == '') {
				this.errorMessage = '비밀번호를 입력해주세요.'
				return
			}
			if (this.new_password == '' && isTempLogin) {
				this.errorMessage = '변경할 새 비밀번호를 입력해주세요.'
				return
			}

			this.loading = true

			var data = {id: this.id, password: this.password, mode: 'login'}
			if (isTempLogin) {
				data['mode'] = 'login_temp'
				data['new_password'] = this.new_password
			}
			this.$axios.post('/user/login', data).then(resp => {
				this.password = ''
				this.$store.commit('user', resp.data.user)
				setTimeout(() => this.$router.push({name: 'user_home'}), 1)
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '로그인 중 오류가 발생하였습니다.'
				setTimeout(() => this.errorMessage = message, 10)
				//alert(message)
			}).finally(() => {
				this.loading = false
			})
		},
		removeError () {
			this.errorMessage = ''
		}
	}
}
</script>

<style lang="scss">
.login-wrapper {
	max-width: 440px;
	margin: 16px auto;
	border: 1px solid #ddd;
	padding: 16px;

	a {
		cursor: pointer;
	}
	h4 {
		text-align: center;
		margin-bottom: 16px;
	}
	input {
		margin-bottom: 8px;
	}

	.field {
		margin-bottom: 10px;
	}

	.forgot-password {
		a {
			color: #333;
		}
	}
}
</style>
