<template>
	<div class="container page page-about">
		<ul class="nav nav-pills nav-justified">
			<li class="nav-item"><a class="nav-link" :class="{active: shownTab == 'about'}" @click="shownTab = 'about'">소개</a></li>
			<li class="nav-item"><a class="nav-link" :class="{active: shownTab == 'greeting'}" @click="shownTab = 'greeting'">인사말</a></li>
			<li class="nav-item"><a class="nav-link" :class="{active: shownTab == 'executive'}" @click="shownTab = 'executive'">임원진</a></li>
		</ul>
		<div class="tab-content">
			<div class="tab-pane" :class="{active: shownTab == 'about'}" role="tabpanel">
				<div class="about-image">
					<img src="/static/images/about.png">
				</div>
				<div class="about-block">
					<h3>PassQ 소개</h3>
					<p>2003년 개설된 초·중·고 학생 및 학교밖청소년, 학부모, 교사 등 누구나 이용할 수 있는 온라인 상담 사이트입니다.</p>
					<p><b>각 분야에 특화된 전문 상담자 60여 명이 지속적인 연수 아래, 체계적인 상담을 실시하고 있습니다.</b></p>
					<p>2003년 개설된 이래 지금까지 연평균 약 350여 건, 2020년 10월 현재 총 6,100여 건의 상담 실적을 쌓았습니다.</p>
				</div>
				<div class="about-block">
					<h3><span class="green"></span>열린 상담실</h3>
					<p>
						2003년 개설될 때부터 시작한 상담실입니다.
						이용자별(초·중·고 학생 및 학교밖청소년, 학부모, 특수아), 상담 내용별(생활교육/대인관계/가족/진로‧진학/학습/성격/정신건강/자해/성‧이성/기타)로 상담을 하고 있습니다.
					</p>
				</div>
				<div class="about-block">
					<h3><span class="pink"></span>자살위기상담실</h3>
					<p>2017년 6월에 문을 연 ‘위기상담실(자살예방비밀상담실)’은 자살 위기 상담을 하고 있습니다.</p>
					<p>자살을 생각할 만큼 힘든 상황에 있는 내담자와 함께 고통을 나누고 삶의 의지를 북돋우는 도움을 주고 있습니다.</p>
					<p>글을 올릴 수도 없을 정도로 힘든 위기에 있는 내담자라면 전화 연결을 하는 등 다양한 방식으로 대응하고 있습니다.</p>
				</div>
				<div class="about-block">
					<h3><span class="blue"></span>학부모상담실</h3>
					<p>부모가 행복해야 자녀도 행복합니다!</p>
					<p>이 공간은 학부모상담을 위해 특별히 만든 공간으로, 부모상담에 특화된 상담자들이 여러분을 기다리고 있습니다.</p>
				</div>
				<div class="about-block">
					<h3><span class="yellow"></span>교원고충상담실</h3>
					<p>2017년 5월 개설한 ‘교원고충비밀상담실 교원힐링센터’, 교원들의 다양한 고충에 대해 도움을 드리기 위한 온라인 상담실입니다.</p>
					<p>한국카운슬러협회 대구지회 현직·전직 임원 등으로 구성된 수퍼바이저 카운슬러가 답변을 진행합니다.</p>
					<p>다양하고 풍부한 경험과 전문성을 겸비한 상담자가 교원들과 고충을 함께 나누고 해결해나가는 목표를 갖고 있습니다.</p>
				</div>
			</div>
			<div class="tab-pane" :class="{active: shownTab == 'executive'}" role="tabpanel">
				<h4 style="text-align: center; margin-bottom: 16px; font-weight: bold;">PassQ 임원진</h4>
				<table class="table table-bordered executives">
					<tr v-for="exec in executives" :key="exec.header">
						<th>{{ exec.header }}</th>
						<td>{{ exec.data }}</td>
					</tr>
				</table>
			</div>
			<div class="tab-pane" :class="{active: shownTab == 'greeting'}" role="tabpanel">
				<GreetingCard />
			</div>
		</div>
	</div>
</template>

<script>
import GreetingCard from './components/GreetingCard.vue'
export default {
	name: 'about',
	components: { GreetingCard, },
	data () {
		return {
			shownTab: 'about',
			executives: [
				{header: '회장', data: '이철구(前 서부교육지원청교육장, 現 동도초 교장)'},
				{header: '수석부회장', data: '권환선(교육권보호지원단)'},
				{header: '부회장', data: '변기흠(前 상서중 교장), 이혜경(수성대 간호학과 교수), 김미향(월배중 보건교사), 이재식(경암중 전문상담교사), 이상숙(관천중 교장)'},
				{header: '사무국장', data: '유영달(달성중학교 교감)'},
        {header: '총무', data: '공서린(현풍초 교사)'},
				{header: '고문', data: '안희원(前 대구일마이스터고 교장), 김남옥(前 동부중 교장), 신재진(前 대진초 교장), 추석호(前 대구교육연수원장), 김진규(前 원화중 교장), 최성식(전 천내초 교장)'},
				{header: '자문위원', data: '김규학(前 대구광역시 시의원), 전경원(대구광역시 시의원), 손인화(대구청소년연구회 회장)'}
			]
		}
	}
}
</script>

<style lang="scss">
.page-about .nav {
	margin-bottom: 16px;

	.nav-link {
		cursor: pointer;

		&:not(.active) {
			border: 1px solid #ddd;
		}
	}
}
.about-image {
	text-align: center;

	img {
		max-width: 240px;
	}
}
.about-block {
	margin-bottom: 32px;

	h3 {
		margin: 0 0 16px;
		font-weight: bold;
		font-size: 28px;

		span:before {
			content: '● ';
			position: relative;
			bottom: 3px;
			color: #666;
			font-size: 16px;
		}

		span.green:before {
			color: rgb(186, 213, 66);
		}
		span.pink:before {
			color: rgb(255, 114, 174);
		}
		span.blue:before {
			color: rgb(42, 133, 250);
		}
		span.yellow:before {
			color: rgb(248, 220, 105);
		}
	}

	p {
		font-size: 16px;
		line-height: 28px;
		margin: 4px;
		text-indent: 16px;
	}
}
.executives {
	th {
		width: 120px;
		text-align: center;
		background-color: #efefef;
	}
	td {
		vertical-align: middle;
	}
}
</style>
