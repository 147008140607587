<template>
	<footer>
		<div class="site-info">
			<b>© 2022 한국카운슬러협회 대구지회 : 대구광역시 서구 서대구로3길 5,(내당동, 서부교육지원청)</b><br>
			<template v-if="false">사이트 운영 및 관리 - 사업자등록번호: 435-31-00855 (씨이앗) | 대표자: 김승호 | dev@c2a.kr</template>
		</div>
		<router-link :to="{name: 'support'}">사이트이용문의</router-link>
	</footer>
</template>

<script>
export default {
	name: 'PageFooter',
	data () {
		return {

		}
	}
}
</script>

<style lang="scss" scoped>
footer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 12px 32px;
	border-top: 1px solid #eaeaea;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);

	font-size: 14px;

	a {
		float: right;
		word-break: keep-all;
		color: #333;

		&:hover {
			color: #333;
			text-decoration: none;
		}
	}
	
	.site-info {
		float: left;
		font-size: 0.9em;
		color: #888;
	}
}
</style>
