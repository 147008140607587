<template>
	<div class="container page">
		<MenuCard :bid="'user_home'" class="mini" />
		<ul class="nav nav-pills nav-justified">
			<li class="nav-item"><a class="nav-link" :class="{active: shownTab == 'incharge'}" @click="changeTab('incharge')">내 분야 상담글</a></li>
			<li class="nav-item"><a class="nav-link" :class="{active: shownTab == 'replied'}" @click="changeTab('replied')">내가 답변한 글</a></li>
		</ul>
		<div class="tab-content">
			<div class="tab-pane active" role="tabpanel">
				<CounselListTable ref="table" :uri="'/user/articles/' + shownTab" :overall="true"></CounselListTable>
			</div>
		</div>
	</div>
</template>

<script>
import MenuCard from '../components/MenuCard.vue'
import CounselListTable from '../Counsel/components/CounselListTable.vue'

export default {
	name: 'UserHome',
	components: {MenuCard, CounselListTable},
	data () {
		return {
			shownTab: 'incharge',
		}
	},
	methods: {
		changeTab(tab) {
			this.shownTab = tab
			setTimeout(() => {
				this.$refs.table.loadList(1)
			}, 1)
		}
	}
}
</script>

<style lang="scss">
.nav .nav-link {
	cursor: pointer;

	&:not(.active) {
		border: 1px solid #ddd;
	}
}

.tab-content {
	margin-top: 32px;
}
</style>
