<template>
	<div class="container page">
		<h4>사용자 정보</h4>
		<div class="user">
			<div class="info">
				<b>이름: </b>{{ user.name }}
			</div>
			<div class="info">
				<b>소속: </b>{{ user.affiliation }}
			</div>
			<div class="info">
				<b>직위: </b>{{ user.position }}
			</div>
			<div class="info">
				<b>휴대폰번호: </b>{{ user.phone }}
			</div>
		</div>
		<button class="btn btn-sm btn-primary" @click="showModal(true)">수정</button>
		<div class="modal" v-if="modalShown"><div class="modal-content">
			<UserInfoForm :mode="'modify'" :user="user" :admin="true" :errorMessage="errorMessage" @click="modifyClicked" @close="showModal(false)" />
		</div></div>
		<hr>
		<h4>답변을 작성한 글 목록</h4>
		<div>
			<CounselListTable ref="table" :uri="'/admin/users/' + user_idx + '/replied'" :overall="true"></CounselListTable>
		</div>
	</div>
</template>

<script>
import UserInfoForm from '../components/UserInfoForm.vue'
import CounselListTable from '../Counsel/components/CounselListTable.vue'

export default {
	name: 'AdminUserPage',
	components: {UserInfoForm, CounselListTable},
	computed: {
		user_idx () { return this.$route.params.user_idx }
	},
	data () {
		return {
			user: {
				id: '', new_pw: '', name: '', phone: '', affiliation: '', position: '',
				experience: [], license: [], fields: [], gubuns: []
			},
			modalShown: false,
			errorMessage: '',
		}
	},
	mounted () {
		this.loadUser()
	},
	methods: {
		loadUser() {
			this.$axios.get(`/admin/users/${this.user_idx}`).then(resp => {
				for (let key in this.user) {
					this.user[key] = resp.data.user[key]
				}
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '정보를 불러오는 중 오류가 발생하였습니다.'
				alert(message)
			}).finally(() => {
				this.loading = false
			})
		},
		showModal(show) {
			this.errorMessage = ''
			this.modalShown = show
			document.body.style.overflow = show ? 'hidden' : ''
		},
		modifyClicked() {
			if (this.loading) return
			this.loading = true
			this.errorMessage = ''
			
			this.$axios.patch('/user', this.user).then(resp => {
				alert('수정 완료하였습니다.')
				this.showModal(false)
				this.loadUser()
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '정보 수정 중 오류가 발생하였습니다.'
				setTimeout(() => this.errorMessage = message, 10)
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.user {
	.info {
		margin-bottom: 8px;
	}
}
</style>
