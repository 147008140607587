import { render, staticRenderFns } from "./CounselWrite.vue?vue&type=template&id=b674b442&scoped=true&"
import script from "./CounselWrite.vue?vue&type=script&lang=js&"
export * from "./CounselWrite.vue?vue&type=script&lang=js&"
import style0 from "./CounselWrite.vue?vue&type=style&index=0&id=b674b442&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b674b442",
  null
  
)

export default component.exports