<template>
	<div class="greeting">
		<h4 @click="toggle">인사말 <span v-if="useCollapse" class="collapse-greeting">{{ shown ? '접기' : '열기' }}</span></h4>
		<div v-show="shown">
			<img src="/static/images/president.png">
			안녕하세요?<br>
			패스큐 회장 이철구입니다.<br><br>
			패스큐는 대구교육청의 지원을 받아 운영하는 온라인 전문 상담 사이트입니다.<br>
			누구나 마음이 힘들거나 어려움이 있을 때 방문해주세요.<br>
			학생 상담, 학부모 상담, 상담 선생님 상담도 패스큐의 전문 상담사들이 함께 하겠습니다.<br>
			모든 상담은 비밀이 보장되고 익명으로 24시간 상담이 가능합니다.<br>
			여러분의 방문을 늘 기다리겠습니다.<br>
			감사합니다.<br><br>
			<div class="sign">패스큐 회장 이철구 드림</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['useCollapse'],
	data() {
		return {
			shown: true
		}
	},
	methods: {
		toggle() {
			if (!this.useCollapse) return
			this.shown = !this.shown
		}
	}
}
</script>
<style lang="scss">
.greeting {
	font-size: 16px;
	padding: 32px;
	border: 1px solid #ddd;
	border-radius: 8px;
	box-shadow: 2px 4px 6px rgb(0 0 0 / 40%);
	margin-bottom: 32px;
	
	h4 {
		font-size: 25px;
		border-bottom: 1px solid #ddd;
		padding-bottom: 12px;
		margin-bottom: 12px;
		
		.collapse-greeting {
			font-size: 14px;
			font-weight: normal;
			float: right;
			line-height: 40px;
			cursor: pointer;
		}
	}
	img {
		width: 140px;
		margin: 4px;
		float: right;
	}
	.sign {
		text-align: right;
	}
}
</style>