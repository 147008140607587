<template>
	<div class="container page">
		<MenuCard :bid="bid" class="mini" />
		<div style="margin-bottom: 16px;" v-if="is_admin">
			<router-link :to="{name: 'BoardWrite'}" role="button" class="btn btn-primary btn-block">글쓰기</router-link>
		</div>
		<div class="board_notice">
			<div class="bl_head">
				<div class="bl_wrap">
					<div class="bl_no">번호</div>
					<div class="bl_title">제목</div>
					<div class="bl_name">작성자</div>
					<div class="bl_date">작성일</div>
					<div class="bl_count">조회수</div>
				</div>
			</div>
			<div class="bl_body">
				<ul>
					<li v-if="loading" class="info"><div class="bl_wrap">불러오는 중...</div></li>
					<li v-if="!loading && articles.length == 0" class="info"><div class="bl_wrap">표시할 글이 없습니다.</div></li>
					<li v-for="article in articles" :key="article.aid">
						<router-link :to="{name: 'BoardView', params: {bid: bid, aid: article.aid}}">
							<div class="bl_wrap">
								<div class="bl_no">{{ article.aid }}</div>
								<div class="bl_title">{{ article.title }}</div>
								<div class="bl_name">{{ article.author }}</div>
								<div class="bl_date">{{ article.date }}</div>
								<div class="bl_count">-</div>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
		<Pagination v-if="!loading" :current=currentPage :last=lastPage @click="pageChange"></Pagination>
	</div>
</template>

<script>
import MenuCard from '../components/MenuCard.vue'
import Pagination from '../components/Pagination.vue'

export default {
	name: 'BoardList',
	components: {MenuCard, Pagination},
	data () {
		return {
			loading: true,
			articles: [],
			currentPage: 1,
			lastPage: 1
		}
	},
	watch: {
		'$route' (to, from) {
			this.loadList(1)
		}
	},
	mounted () {
		if (this.$store.state.user == null) {
			this.$router.push({name: 'login'})
		}else{
			this.loadList(1)
		}
	},
	computed: {
		bid () { return this.$route.params.bid },
		is_admin() { return this.$store.state.user != null && this.$store.state.user.admin }
	},
	methods: {
		loadList (page) {
			this.loading = true
			this.articles.splice(0)

			this.$axios.get(`/board/${this.bid}`, {params: {page: page}}).then(resp => {
				this.currentPage = resp.data.page
				this.lastPage = resp.data.last_page

				resp.data.articles.forEach(row => this.articles.push(row))
			}).catch(e => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '목록을 불러오는 중 오류가 발생하였습니다.'
				alert(message)
			}).finally(() => {
				this.loading = false
			})
		},
		pageChange (page) {
			if (this.loading) return

			this.loadList(page)
		}
	}
}
</script>

<style lang="scss">
.board_notice {
	border-top: #4c4c4c solid 2px;

	.bl_head {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		padding: 8px 0;
		border-bottom: #efefef solid 1px;
		color: #201f2d;
		text-align: center;
		font-size: 15px;
		font-weight: 500;

		.bl_title {
			text-align: center;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.bl_wrap {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		width: 100%;
		text-align: center;

		@media (max-width: 768px) {
			text-align: left;
		}
	}

	.bl_body {
		font-size: 14px;

		ul {
			list-style: none;
			padding: 0;

			li {
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				padding: 6px 0;
				border-bottom: #efefef solid 1px;
				list-style: none;

				a {
					color: #555;
				}

				&:hover {
					background-color: #f9f9f9;
				}

				@media (max-width: 768px) {
					padding: 6px 8px;
				}

				&.info {
					background-color: #fff;

					.bl_wrap {
						text-align: center !important;
					}
				}
			}
		}
	}

	.bl_no {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		left: 0;
		top: 0;
		width: 70px;

		@media (max-width: 992px) {
			width: 60px;
		}
	}

	.bl_title {
		margin-left: 70px;
		margin-right: 360px;
		text-align: left;

		@media (max-width: 992px) {
			margin-left: 60px;
			margin-right: 320px;
		}
		@media (max-width: 768px) {
			margin: 20px 0;
			font-size: 14px;
			padding: 5px 0;
			white-space: normal;
			word-break: normal;
		}
	}
	
	.bl_name {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		right: 210px;
		top: 0;
		width: 150px;

		@media (max-width: 992px) {
			right: 190px;
			width: 130px;
		}
		@media (max-width: 768px) {
			text-align: right;
			right: 0;
			text-indent: 0;
		}
	}
	.bl_date {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		right: 100px;
		top: 0;
		width: 110px;

		abbr {
			text-decoration: none;
		}

		@media (max-width: 992px) {
			right: 80px;
			width: 110px;
		}
		@media (max-width: 768px) {
			left: 0;
			right: auto;
			top: auto;
			bottom: 0;
		}
	}
	.bl_count {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		right: 0;
		top: 0;
		width: 100px;

		@media (max-width: 992px) {
			width: 80px;
		}
		@media (max-width: 768px) {
			right: 0;
			top: auto;
			bottom: 0;
			text-align: right;
		}
	}
}
</style>
