import 'babel-polyfill'
import Vue from 'vue'

import api from './api.js'
Vue.use(api)

import store from './store.js'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import nprogress from './nprogress.js'
Vue.use(nprogress)

import App from './App.vue'

const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Vue Router
import NotFound from './views/NotFound.vue'
import Parking from './views/Parking.vue'
import IEError from './views/IEError.vue'

import Home from './views/Home.vue'
import About from './views/About.vue'
import Login from './views/Login.vue'
import Join from './views/Join.vue'
import Support from './views/Support.vue'

import CounselList from './views/Counsel/CounselList.vue'
import CounselWrite from './views/Counsel/CounselWrite.vue'
import CounselView from './views/Counsel/CounselView.vue'

import BoardList from './views/Board/BoardList.vue'
const BoardWrite = isIE ? IEError : () => import(/* webpackChunkName: "chrome" */ './views/Board/BoardWrite.vue');
//import BoardWrite from './views/Board/BoardWrite.vue'
import BoardView from './views/Board/BoardView.vue'

import AdminUsers from './views/AdminMenu/AdminUsers.vue'
import AdminUserPage from './views/AdminMenu/AdminUserPage.vue'
import AdminStats from './views/AdminMenu/AdminStats.vue'

import UserHome from './views/UserMenu/UserHome.vue'
import UserNoti from './views/UserMenu/UserNoti.vue'
import UserInfo from './views/UserMenu/UserInfo.vue'

const router = new VueRouter({
	routes: [
		{name: 'home', path: '/', component: Home},
		{name: 'about', path: '/about', component: About},
		{name: 'support', path: '/support', component: Support},

		{name: 'CounselList', path: '/counsel/:bid', component: CounselList},
		{name: 'CounselWrite', path: '/counsel/:bid/write', component: CounselWrite, props: true},
		{name: 'CounselView', path: '/counsel/:bid/:aid', component: CounselView},

		{name: 'BoardList', path: '/board/:bid', component: BoardList},
		{name: 'BoardWrite', path: '/board/:bid/write', component: BoardWrite, props: true, meta: { authorized: true }},
		{name: 'BoardView', path: '/board/:bid/:aid', component: BoardView},

		{name: 'login', path: '/login', component: Login},
		{name: 'join', path: '/join', component: Join},

		{name: 'admin_stats', path: '/admin/stats', component: AdminStats, meta: { authorized: true }},
		{name: 'admin_users', path: '/admin/users', component: AdminUsers, meta: { authorized: true }},
		{name: 'admin_user_page', path: '/admin/users/:user_idx', component: AdminUserPage, meta: { authorized: true }},

		{name: 'user_home', path: '/user', component: UserHome, meta: { authorized: true }},
		{name: 'user_noti', path: '/user/noti', component: UserNoti, meta: { authorized: true }},
		{name: 'user_info', path: '/user/info', component: UserInfo, meta: { authorized: true }},

		{name: '404', path: '/404', component: NotFound, alias: '*'}
		//{path: '*', redirect: '/404'},
	]
})

router.beforeEach(async (to, from, next) => {
	if (!store.state.checkLogin) {
		await store.dispatch('checkLogin')
	}

	let logged = store.state.user != null
	if (logged && to.name == 'login') {
		next({name: 'user_home'})
	}else if (to.matched.some(record => !record.meta.authorized) || logged) {
		next()
	}else {
		next({name: 'login'}) //-> redirect guard error
		//router.push({name: 'login'})
	}
})


// Init App
Vue.config.productionTip = false

new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')
