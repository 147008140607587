<template>
	<div class="comments">
		<div class="comment" :class="{re: index != 0}" v-for="(cmt, index) in comments" :key="cmt.id">
			<div class="comment-header">
				<div class="author">{{ cmt.author }}</div>
				<div class="header-right">
					<div class="options" v-if="cmt.author_idx == my_idx">
						<a @click="modifyClicked(index)">{{ modifying_index == index ? '취소' : '수정' }}</a> · <!--<a @click="deleteClicked">삭제</a> · -->
					</div>
					<div class="date">{{ cmt.date }}</div>
				</div>
			</div>
			<div class="comment-content" v-show="modifying_index != index" v-html="cmt.content_escaped"></div>
			<textarea class="comment-textarea" v-if="modifying_index == index" v-model="new_value"></textarea>
			<div class="comment-footer">
				<template v-if="modifying_index == index">
					<button class="comment-cancel-button" @click="modifyClicked(index)">취소</button>
					<button class="comment-write-button" @click="modifySubmitClicked" :disabled="loading">게시</button>
				</template>
				<template v-if="index == comments.length - 1">
					<a @click="replyClicked" v-if="modifying_index != index">{{ showReplyForm ? '취소' : '대댓글' }}</a>
				</template>
			</div>
		</div>
		<CounselRate v-if="showRate" :readonly="my_idx !== 0" :author="comments[0].author" :rate="comments[0].rate" @changed="rateUpdated" />
		<CounselCommentWriteForm class="re" v-show="showReplyForm" v-on="$listeners" :cid="comments[0].id" :articleAuthorName="articleAuthorName" />
	</div>
</template>
<style lang="scss" scoped>
.form-rate {
	padding: 8px;
	margin-bottom: 8px;
	text-align: left;
	text-align: center;
	
	.rates {
		display: flex;
		justify-content: center;
		gap: 4px;
		margin-top: 4px;
		
		.star {
			background-image: url('./assets/star.png');
			width: 30px;
			height: 30px;
			background-size: cover;
			
			&:hover {
				background-image: url('./assets/star-fill.png');
			}
		}
	}
	.rate-text {
		color: #aaa;
		margin-top: 8px;
		font-size: 0.9em;
	}
}
</style>
<script>
import CounselCommentWriteForm from './CounselCommentWriteForm.vue'
import CounselRate from './CounselRate.vue'

export default {
	name: 'CounselComments',
	components: { CounselCommentWriteForm, CounselRate },
	props: ['comments', 'articleAuthorName'],
	data () {
		return {
			showReplyForm: false,
			modifying_index: null,
			new_value: '',
			loading: false
		}
	},
	computed: {
		my_idx () { return this.$store.state.user != null ? this.$store.state.user.idx : 0 },
    showRate () {
      return this.my_idx === 0 || this.my_idx === this.comments[0].author_idx || this.$store.state.user.admin
    }
	},
	methods: {
		hideReplyForm () {
			this.showReplyForm = false
		},
		replyClicked () {
			this.showReplyForm = !this.showReplyForm
		},
		modifyClicked (index) {
			if (this.modifying_index == index) {
				this.modifying_index = null
				return
			}else{
				this.modifying_index = index
				this.new_value = this.comments[index].content
			}
		},
		modifySubmitClicked () {
			if (this.modifying_index == null || this.loading) return
			
			var comment = this.comments[this.modifying_index]
			
			this.loading = true
			var form = {content: this.new_value, cid: comment.id, password: this.$parent.password}
			this.$axios.patch(`/counsel/${this.$parent.bid}/${this.$parent.aid}/comment`, form).then(resp => {
				comment.content = resp.data.content
				comment.content_escaped = resp.data.content_escaped
				
				this.modifying_index = null
			}).catch(e => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '댓글 수정 중 오류가 발생하였습니다.'
				alert(message)
			}).finally(() => {
				this.loading = false
			})
		},
		rateUpdated(rate) {
      if (this.my_idx !== 0) return;
			const orgRate = this.comments[0].rate;
			this.comments[0].rate = rate
			var form = {cid: this.comments[0].id, password: this.$parent.password, rate}
			this.$axios.post(`/counsel/comment/rate`, form).catch(e => {
				this.comments[0].rate = orgRate
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '평가 등록 중 오류가 발생하였습니다.'
				alert(message)
			})
		}
	}
}
</script>