<template>
	<div class="container page">
		<MenuCard :bid="'admin_users'" class="mini" />
    <div style="margin-bottom: 16px;">
      <a class="btn btn-block btn-info" href="/b/admin/users?download=1" target="_blank">엑셀 저장</a>
    </div>
		<table id="dtable" class="display responsive nowrap" width="100%"></table>
		<div class="modal" v-if="modalShown"><div class="modal-content">
			<UserInfoForm :mode="'modify'" :user="user" :admin="true" :errorMessage="errorMessage" @click="modifyClicked" @close="showModal(false)" />
		</div></div>
	</div>
</template>

<script>
import MenuCard from '../components/MenuCard.vue'
import UserInfoForm from '../components/UserInfoForm.vue'

import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-dt/css/jquery.dataTables.css'

export default {
	name: 'AdminUsers',
	components: {MenuCard, UserInfoForm},
	data () {
		return {
			loading: false,
			modalShown: false,
			errorMessage: '',
			user: {
				id: '', new_pw: '', name: '', phone: '', affiliation: '', position: '',
				experience: [], license: [], fields: [], gubuns: []
			}
		}
	},
	mounted () {
		var dtable = $('#dtable').DataTable({
			columns: [
				{'class': 'details-control', 'orderable': false, 'data': null, 'defaultContent': ''},
				{title: 'No', data: 'idx'},
				{title: 'ID', data: 'user_id'},
				{title: '이름', data: 'name'},
				{title: '소속', data: 'affiliation'},
				{title: '직위', data: 'position'},
				{title: '승인', data: 'approved', render (data, type, row, meta) {
					if (data == 1) {
						return row['admin'] ? '승인됨 (관리자)' : '승인됨'
					}else{
						return '승인 대기'
					}
				}}
			],
			order: [[1, 'desc']],
			ajax: {url: '/b/admin/users'},
			language: {url: '/static/dataTables.ko.json'},
			pageLength: 10,
			lengthMenu: [5, 10, 25, 100],
			responsive: true
		})
		this.dtable = dtable

		var format = function(d) {
			var fields = [... d.fields]
			var board_fields = []
			var boards = {'emerg': '자살위기상담실', 'parents': '학부모상담실', 'teacher': '교원고충상담실', 'meta': '메타버스상담실'}
			for (let key in boards) {
				let idx = $.inArray(key, fields)

				if (idx !== -1) {
					fields.splice(idx, 1)
					board_fields.push(boards[key])
				}
			}
			fields = fields.concat(board_fields)

			var experience = d.experience.map(el => `${el.organ} ${el.position} (${el.start}~${el.end})`).join(', ')
			var license = d.license.map(el => `${el.name} (${el.date})`).join(', ')
			var row = `<b>휴대폰번호:</b> ${d.phone}<br><hr>
					<b>상담 경력:</b> ${experience}<br><hr>
					<b>자격증:</b> ${license}<br><hr>
					<b>상담구분:</b> ${d.gubuns.join(', ')}<hr>
					<b>상담분야:</b> ${fields.join(', ')}<hr>`

			if (d.approved == 0) {
				row += `<button class="btn btn-sm btn-warning" data-idx="${d.idx}">승인</button>
						<button class="btn btn-sm btn-danger" data-idx="${d.idx}">거절(삭제)</button>`
			}else{
				row += `<button class="btn btn-sm btn-primary" data-idx="${d.idx}">수정</button> `

				if (d.admin) {
					if (d.idx != 1) {
						row += `<button class="btn btn-sm btn-dark" data-idx="${d.idx}">관리자해제</button>`
					}
				}else{
					row += `<button class="btn btn-sm btn-success" data-idx="${d.idx}">관리자지정</button>`
				}
			}

			return row
		}

		$('#dtable tbody').on('click', 'tr', function() { //  td.details-control
			var tr = $(this) //.closest('tr')
			var row = dtable.row(tr)

			if (row.child.isShown()) {
				tr.removeClass('details')
				row.child.hide()
			}else{
				tr.addClass('details')
				row.child(format(row.data())).show()
			}
		})

		var self = this
		$('#dtable tbody').on('click', '.btn-sm', function() {
			var $this = $(this)
			var idx = $this.data('idx')

			if ($this.hasClass('btn-warning')) {
				if (confirm('승인 처리 하시겠습니까? 승인 처리 문자 알림이 전송됩니다.')) {
					self.approveUser(idx, true)
				}
			}else if ($this.hasClass('btn-danger')) {
				if (confirm('승인 거절하시겠습니까? 승인 거절 문자 알림 후 해당 사용자 정보가 삭제됩니다.')) {
					self.approveUser(idx, false)
				}
			}else if ($this.hasClass('btn-primary')) {
				var tr = $this.closest('tr').prev()
				var row = dtable.row(tr)
				var data = row.data()

				for (let key in self.user) {
					if (key == 'id') self.user['id'] = data['user_id']
					else {
						self.user[key] = data[key]
					}
				}
				self.showModal(true)
			}else if ($this.hasClass('btn-success')) {
				if (confirm('해당 사용자를 관리자로 지정하겠습니까?')) {
					self.makeUserAdmin(idx, true)
				}
			}else if ($this.hasClass('btn-dark')) {
				if (confirm('해당 사용자의 관리자 권한을 취소하겠습니까?')) {
					self.makeUserAdmin(idx, false)
				}
			}
		})
	},
	methods: {
		approveUser(user_idx, approve) {
			var data = {idx: user_idx, approve: approve}
			this.$axios.post('/admin/users/approve', data).then(resp => {
				this.dtable.ajax.reload()
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '승인 처리 중 오류가 발생하였습니다.'
				alert(message)
			})
		},
		makeUserAdmin(user_idx, admin) {
			var data = {idx: user_idx, admin: admin}
			this.$axios.post('/admin/users/admin', data).then(resp => {
				this.dtable.ajax.reload()
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '처리 중 오류가 발생하였습니다.'
				alert(message)
			})
		},
		showModal(show) {
			this.errorMessage = ''
			this.modalShown = show
			document.body.style.overflow = show ? 'hidden' : ''
		},
		modifyClicked() {
			if (this.loading) return
			this.loading = true
			this.errorMessage = ''

			this.$axios.patch('/user', this.user).then(resp => {
				alert('수정 완료하였습니다.')
				this.showModal(false)
				this.dtable.ajax.reload()
			}).catch((e, xhr) => {
				var message = (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined)
					? e.response.data.error : '정보 수정 중 오류가 발생하였습니다.'
				setTimeout(() => this.errorMessage = message, 10)
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss">
#dtable td.details-control {
    background: url('/static/images/details_open.png') no-repeat center center;
    cursor: pointer;
}
#dtable tr.details td.details-control {
    background: url('/static/images/details_close.png') no-repeat center center;
}

.modal {
	/* https://yuddomack.tistory.com/entry/Modal-%EB%A7%8C%EB%93%A4%EA%B8%B0body-scroll-lock */

	display: block;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(138, 138, 138, 0.7);
	z-index: 100;

	.modal-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: white;
		max-height: 90vh;
		overflow-y: scroll;
		border-radius: 4px;

		padding: 0;

		.reg-form {
			margin: 0;
		}

		width: 540px;
		@media (max-width: 575px) {
			width: 100%;
		}
	}
}
</style>
