<template>
	<div class="comment write">
		<div class="comment-author">
			{{ print_name }}
			<button class="comment-write-button" @click="buttonClicked" :disabled="loading">게시</button>
		</div>
		<textarea v-model="value"></textarea>
		<button v-if="false" @click="buttonClicked" :disabled="loading">댓글 달기</button>
	</div>
</template>
<script>
export default {
	name: 'BoardCommentWriteForm',
	props: ['cid'],
	data () {
		return {
			value: '',
			loading: false
		}
	},
	computed: {
		print_name () {
			return this.$store.state.user !== null ? this.$store.state.user.name : 'Unknown'
		}
	},
	methods: {
		buttonClicked () {
			if (this.value.trim() != '') {
				this.$emit('write', this, this.cid, this.value.trim())
			}
		}
	}
}
</script>