<template>
	<div class="container page">
		<div class="row">
			<div class="col-lg-6 col-md-12">
				<router-link :to="{name: 'CounselList', params: {bid: 'open'}}">
					<MenuCard :bid="'open'" class="md-nano" />
				</router-link>
			</div>
			<div class="col-lg-6 col-md-12">
				<router-link :to="{name: 'CounselList', params: {bid: 'emerg'}}">
					<MenuCard :bid="'emerg'" class="md-nano" />
				</router-link>
			</div>
			<div class="col-lg-6 col-md-12">
				<router-link :to="{name: 'CounselList', params: {bid: 'parents'}}">
					<MenuCard :bid="'parents'" class="md-nano" />
				</router-link>
			</div>
			<div class="col-lg-6 col-md-12">
				<router-link :to="{name: 'CounselList', params: {bid: 'teacher'}}">
					<MenuCard :bid="'teacher'" class="md-nano" />
				</router-link>
			</div>
      <div class="col-lg-6 col-md-12">
				<router-link :to="{name: 'CounselList', params: {bid: 'meta'}}">
					<MenuCard :bid="'meta'" class="md-nano" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import MenuCard from './components/MenuCard.vue'

export default {
	name: 'home',
	components: {MenuCard},
	mounted() {
		// if (!window.localStorage?.getItem('popup')) {
		// 	window.open('/popup.html', '', 'width=660px,height=490px')
		// }
	}
}
</script>

<style lang="scss" scoped>
a:hover {
	text-decoration: none;
}

</style>
