<template>
	<div class="reg-form">
		<h4 v-if="mode == 'join'">상담사 회원가입</h4>
		<h4 v-if="mode == 'modify'">정보 수정</h4>
		<div class="field">
			<label>ID<span>*</span></label>
			<div><input type="text" v-model="user.id" :disabled="mode == 'modify'"></div>
		</div>
		<div class="field" v-if="mode == 'join'">
			<label>비밀번호<span>*</span></label>
			<div><input type="password" v-model="user.pw"></div>
		</div>
		<div class="field" v-if="mode == 'modify'">
			<label>새 비밀번호</label>
			<div>
				<input type="password" v-model="user.new_pw">
				<p>비밀번호 변경시 입력해주세요.</p>
			</div>
		</div>
		<div class="field">
			<label>이름<span>*</span></label>
			<div><input type="text" v-model.lazy="user.name" :disabled="mode == 'modify' && !isAdminToModify"></div>
		</div>
		<div class="field">
			<label>휴대폰번호<span>*</span></label>
			<div>
				<input type="text" v-model.lazy="user.phone">
				<p>상담글 알림 등에 사용됩니다.</p>
			</div>
		</div>
		<div class="field">
			<label>소속<span>*</span></label>
			<div>
				<input type="text" v-model.lazy="user.affiliation">
				<p>예) 000고등학교</p>
			</div>
		</div>
		<div class="field">
			<label>직위</label>
			<div>
				<input type="text" v-model.lazy="user.position">
				<p>예) 교사, 교수, 전문상담교사, 보건교사 등</p>
			</div>
		</div>
		<div class="field" style="border-bottom: none;">
			<label>상담 경력</label>
			<div>
				<div v-if="user.experience.length == 0" style="line-height: 33px;">등록된 경력이 없습니다.</div>
				<ul class="license-added">
					<li v-for="(exp, index) in user.experience" :key="index">
						{{ exp.organ }} {{ exp.position }}<br>{{ exp.start }} ~ {{ exp.end }}
						<button class="btn btn-sm btn-danger" @click="removeExperience(index)">삭제</button>
					</li>
				</ul>
			</div>
		</div>
		<div class="field" style="display: block;">
			<div class="license-add-form" :class="{'shown': showExperienceForm}">
				<div><label>기관명<span>*</span></label><div><input type="text" v-model.lazy="experience.organ"></div></div>
				<div><label>직위<span>*</span></label><div><input type="text" v-model.lazy="experience.position"></div></div>
				<div><label>시작일<span>*</span></label><div><input type="date" date-polyfill="all" v-model.lazy="experience.start"></div></div>
				<div><label>종료일</label><div><input type="date" date-polyfill="all" v-model.lazy="experience.end"></div></div>
				<div style="margin-bottom: 8px;">항목 입력 후 추가버튼을 눌러주세요.</div>
				<button class="btn btn-sm btn-success btn-block" @click="addExperience">추가</button>
			</div>
		</div>
		<div class="field" style="border-bottom: none;">
			<label>상담관련 자격증</label>
			<div>
				<div v-if="user.license.length == 0" style="line-height: 33px;">등록된 자격증이 없습니다.</div>
				<ul class="license-added">
					<li v-for="(license, index) in user.license" :key="index">
						{{ license.name }}<br>{{ license.organ }} / {{ license.date }}
						<button class="btn btn-sm btn-danger" @click="removeLicense(index)">삭제</button>
					</li>
				</ul>
			</div>
		</div>
		<div class="field" style="display: block;">
			<div class="license-add-form" :class="{'shown': showLicenseForm}">
				<div><label>이름<span>*</span></label><div><input type="text" v-model.lazy="license.name"></div></div>
				<div><label>발급기관<span>*</span></label><div><input type="text" v-model.lazy="license.organ"></div></div>
				<div><label>발급일<span>*</span></label><div><input type="date" date-polyfill="all" v-model.lazy="license.date"></div></div>
				<div style="margin-bottom: 8px;">항목 입력 후 추가버튼을 눌러주세요.</div>
				<button class="btn btn-sm btn-success btn-block" @click="addLicense">추가</button>
			</div>
		</div>
		<div class="field" style="padding: 8px 0;">
			<label>상담구분</label>
			<div>
				<div class="councel-field">
					<label v-for="gubun in gubuns" :key="gubun"><input type="checkbox" :value="gubun" v-model="user.gubuns" :disabled="mode == 'modify' && !isAdminToModify"> {{ gubun }}</label>
				</div>
				<p v-if="mode == 'join'">관리자 확인 후 변경될 수 있습니다.</p>
				<p v-else>변경이 필요할 시 관리자에게 요청해주세요.</p>
			</div>
		</div>
		<div class="field" style="padding: 8px 0;">
			<label>상담분야</label>
			<div>
				<div class="councel-field">
					<h5>◼︎ 열린상담실</h5>
					<label v-for="field in fields" :key="field"><input type="checkbox" :value="field" v-model="user.fields" :disabled="mode == 'modify' && !isAdminToModify"> {{ field }}</label>
				</div>
				<div class="councel-field"><label><input type="checkbox" value="emerg" v-model="user.fields" :disabled="mode == 'modify' && !isAdminToModify"> 자살위기상담실</label></div>
				<div class="councel-field"><label><input type="checkbox" value="parents" v-model="user.fields" :disabled="mode == 'modify' && !isAdminToModify"> 학부모상담실</label></div>
				<div class="councel-field"><label><input type="checkbox" value="teacher" v-model="user.fields" :disabled="mode == 'modify' && !isAdminToModify"> 교원고충상담실</label></div>
        <div class="councel-field"><label><input type="checkbox" value="meta" v-model="user.fields" :disabled="mode == 'modify' && !isAdminToModify"> 메타버스상담실</label></div>
				<p v-if="mode == 'join'">3개 이내로 선택해주세요.<br>관리자 확인 후 변경될 수 있습니다.</p>
				<p v-else>3개 이내로 선택해주세요.<br>변경이 필요할 시 관리자에게 요청해주세요.</p>
			</div>
		</div>
		<div v-if="errorMessage != ''">
			<p style="color: red;">{{ errorMessage }}</p>
		</div>
		<div class="button-area" v-if="mode == 'join'">
			<button class="btn btn-secondary btn-block" @click="doneClicked">가입 요청</button>
			<router-link :to="{name: 'login'}" role="button" class="btn btn-light btn-block">로그인으로 돌아가기</router-link>
		</div>
		<div class="button-area" v-if="mode == 'modify'">
			<button class="btn btn-secondary btn-block" @click="doneClicked">수정</button>
			<button class="btn btn-light btn-block" @click="$emit('close')" v-if="admin">취소</button>
		</div>
	</div>
</template>

<script>
import 'better-dom/dist/better-dom.js'
import 'better-dateinput-polyfill/dist/better-dateinput-polyfill.js'

export default {
	name: 'UserInfoForm',
	props: ['mode', 'user', 'errorMessage', 'admin'],
	data () {
		return {
			showLicenseForm: false,
			license: {name: '', organ: '', date: ''},
			showExperienceForm: false,
			experience: {organ: '', position: '', start: '', end: ''},
			isAdminToModify: false
		}
	},
	mounted () {
		this.isAdminToModify = this.admin || this.$store.state.user.admin
	},
	computed: {
		fields () { return ['생활교육', '대인관계', '가족', '진로•진학', '학습', '성격', '정신건강', '자해', '성•이성', '기타'] },
		gubuns () { return ['초등학생', '중학생', '고등학생', '학교밖청소년', '특수아'] }
	},
	methods: {
		doneClicked () {
			this.$emit('click')
		},
		addLicense () {
			if (!this.showLicenseForm) {
				this.showLicenseForm = true
				return
			}

			var new_license = {
				name: this.license.name.trim(),
				organ: this.license.organ.trim(),
				date: this.license.date.trim()
			}
			if (new_license.name == '' || new_license.organ == '' || new_license.date == '') {
				alert('이름, 발급기관, 발급일을 모두 채워주세요.')
				return
			}
			this.user.license.push(new_license)

			this.license.name = ''
			this.license.organ = ''
			this.license.date = ''
		},
		removeLicense(index) {
			this.user.license.splice(index, 1)
		},
		addExperience () {
			if (!this.showExperienceForm) {
				this.showExperienceForm = true
				return
			}

			var new_exp = {
				organ: this.experience.organ.trim(),
				position: this.experience.position.trim(),
				start: this.experience.start.trim(),
				end: this.experience.end.trim()
			}
			if (new_exp.organ == '' || new_exp.position == '' || new_exp.start == '') {
				alert('기관, 직위, 시작일을 채워주세요.')
				return
			}
			this.user.experience.push(new_exp)

			this.experience.organ = ''
			this.experience.position = ''
			this.experience.start = ''
			this.experience.end = ''
		},
		removeExperience(index) {
			this.user.experience.splice(index, 1)
		}
	}
}
</script>

<style lang="scss">
.reg-form {
	max-width: 640px;
	margin: 16px auto 32px;
	border: 1px solid #ddd;
	padding: 16px;

	h4 {
		text-align: center;
		margin-bottom: 16px;
	}

	input[type=text], input[type=number], input[type=password], input[type=date] {
		width: 100%;
		height: 33px;
		font-size: 16px;
		border: 1px solid #ccc;
		min-width: 120px;
	}
	input[type=number] {
		width: 60px;
		min-width: auto;
	}

	.field {
		display: flex;
		margin-bottom: 8px;
		border-bottom: 1px solid #ddd;

		& > label {
			line-height: 33px;
			width: 140px;

			span {
				margin-left: 4px;
				color: red;
			}
		}
		& > div {
			flex: 1;

			p {
				font-size: 14px;
				color: #106db7;
				margin: 4px 0;
			}

			.councel-field {
				h5 {
					font-size: 16px;
				}
				label {
					margin-right: 12px;
				}
				border-bottom: 1px solid #ddd;
				margin-bottom: 8px;
			}
			.license-added {
				li { position: relative; }
				button {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		@media (max-width: 575px) {
			display: block;
			& > label {
				font-weight: bold;
			}
		}
	}
	.license-add-form {
		max-width: 320px;
		border: 1px solid #ddd;
		padding: 10px;
		margin: 8px auto;

		&.shown > div {
			display: flex
		}
		& > div {
			display: none;

			label {
				width: 90px;
				line-height: 33px;

				span {
					margin-left: 2px;
					color: red;
				}
			}
			& > div {
				flex: 1;
			}
		}
	}
}
</style>
