<template>
	<div class="container page">
		<div class="error-image">
			<img src="/static/images/error.png">
		</div>
		<h4>오픈 대기 중인 기능입니다.<br>조금만 기다려주세요!</h4>
		<div style="margin-top: 16px; text-align: center;">
		이용문의: 메일 dev@c2a.kr
		</div>
	</div>
</template>

<script>
export default {
	name: 'Parking',
	data () {
		return {

		}
	}
}
</script>

<style lang="scss" scoped>
.error-image {
	text-align: center;

	img {
		max-width: 240px;
	}
}

h4 {
	text-align: center;
}
</style>
